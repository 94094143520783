import styles from "./DevelopersStyles.module.css"
const SecretKeyDoc = () => {
    return ( 
        <div className={styles.section}>
        <h2 className={styles.titleHeading}>Secret Key</h2>
        <p className={styles.paragraph}>
          The secret key is used to identify and authenticate the market. It is unique to each market and should be kept confidential.
        </p>
        <h3 className={styles.subSubHeading}>Obtaining Secret Key:</h3>
        <p className={styles.paragraph}>
          Markets must register on the Swinva website and navigate to settings to obtain the secret key. The registration process is simple.
        </p>
        <h3 className={styles.subSubHeading}>Updating Secret Key:</h3>
        <p className={styles.paragraph}>
          Once a new secret key has been generated, the previous secret key becomes invalid.
        </p>
        <h3 className={styles.subSubHeading}>Uploading Secret Key:</h3>
        <p className={styles.paragraph}>
          The inventory software should have an input area for markets to upload the secret key. Before saving the secret key, it is recommended to validate it by making an upsertItems call with an empty items array.
        </p>
        <pre className={styles.code}>
          {`{
secretKey: "uploading secret key",
items: []
}`}
        </pre>
        <p className={styles.paragraph}>
          If the response is OK, the secret key is valid and can be saved for future use.
        </p>
      </div>
     );
}
 
export default SecretKeyDoc;