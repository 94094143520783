import styles from "./DevelopersStyles.module.css"

const DeveloperIntro = () => {
    return ( 
    
        <div className={styles.section}>
            <h1 className={styles.heading}>Documentation</h1>
          <h2 className={styles.titleHeading}>Introduction</h2>
          <p className={styles.paragraph}>
            Welcome to our API documentation designed to seamlessly integrate mobile shopping capabilities into your inventory or sales 
            management software. This guide outlines the implementation steps for Swinva APIs which help to manage supermarket items price 
            tag and empowering your system to effortlessly capture all items shopped by customers through mobile applications with just a single scan. 
            The process is intuitive and straightforward, ensuring a smooth transition to enhanced mobile shopping functionality.

          </p>
          <h4><i>If encountered any difficulties, please don't hesitate to contact us through our dedicated contact page. We're here to assist you and address any challenges you may be facing.</i></h4>
        </div>
     );
}
 
export default DeveloperIntro;