import { useState } from "react";
import ApiService from "../../ApiService";
import Styles from "../SettingsComponents/Settings.module.css";
const KeyGen = () => {
  const [secreteKey, setSecreteKey] = useState("");
  const [loadingApikey, setloadingApiKey] = useState(false);
  const [loadApiKeyErr, setLoadApiKeyErr] = useState("");

  const handleGetSecretKey = async () => {
    setloadingApiKey(true);
    setLoadApiKeyErr("");
    await ApiService.getAsync("Markets/secret-key")
      .then((resp) => {
        setSecreteKey(resp.key);
      })
      .catch((err) => {
        if (err.status === 401) {
          window.location.href = "/login";
          return;
        }
        setLoadApiKeyErr(err.message);
      })
      .finally(() => {
        setloadingApiKey(false);
      });
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(secreteKey)
      .then(() => {
        alert("Copied!");
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  return (
    <div
      style={{
        height: 500,
        backgroundColor: "#f5f5f8",
        borderRadius: 10,
        padding: 20,
      }}
    >
      <label className={Styles.keyHeader}>Get new Secret Key</label>
      <div>
        <p className={Styles.keyNote}>
          Note that generating a new key will render the old key invalid.
        </p>
        <div className={Styles.copyContainer}>
          <p className={Styles.key}>{secreteKey}</p>
          <button
            type="button"
            onClick={handleCopyToClipboard}
            disabled={!secreteKey}
            style={{
              backgroundColor: "#cccccc",
              margin: 10,
              width: 70,
              height: 30,
              color: "#1386F5",
              fontWeight: 600,
              fontSize: 14,
              padding: 5,
            }}
          >
            Copy
          </button>
        </div>
        <button
          type="button"
          onClick={handleGetSecretKey}
          disabled={loadingApikey}
          className={Styles.btn}
        >
          {loadingApikey ? "Generating..." : "Generate"}
        </button>
        {loadApiKeyErr && (
          <small
            style={{
              color: "#CE5A67",
              textAlign: "center",
            }}
          >
            Error: {loadApiKeyErr}
          </small>
        )}
      </div>
    </div>
  );
};

export default KeyGen;
