import { useEffect, useState } from "react";
import ApiService from "../../ApiService";

const Payment = ({ country }) => {
  const [banks, setBanks] = useState([]);
  const [fetchBanksErr, setFetchBanksErr] = useState("");
  const [isFetchingBanks, setIsFetchingBanks] = useState(false);

  const [isPosting, setPostErr] = useState("");
  const [postDataError, setIsPosting] = useState(false);

  const [bankId, setBankId] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    // setIsFetchingBanks(true);
    // setFetchBanksErr("");
    // ApiService.getAsync(`Markets/bank/${country}`)
    //   .then((resp) => {
    //     setBanks(resp);
    //   })
    //   .catch((err) => {
    //     setFetchBanksErr(err.message);
    //   })
    //   .finally(() => {
    //     setIsFetchingBanks(false);
    //   });
  }, []);

  const handleBankChange = (event) => {
    setBankId(event.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // after validation met
    const payment = {
      name: name,
      phone: phone,
      email: email,
      bankId: bankId,
      countryName: country,
    };
    setIsPosting(true);
    setPostErr("");
    ApiService.postAsync("markets/market-payment", payment)
      .then((resp) => {
        alert("success");
      })
      .catch((err) => {
        setPostErr(err.message);
      })
      .finally(setIsPosting(false));
  };
  const [showBankInfo, setShowBankInfo] = useState(false);
  const toggleBankInfo = () => {
    setShowBankInfo(!showBankInfo);
  };

  const buttonStyle = {
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    padding: "4px 8px",
    cursor: "pointer",
    marginBottom: "16px",
    fontSize: "small",
    width: "100%",
  };

  return (
    <div>
      <button onClick={toggleBankInfo} style={buttonStyle}>
        {showBankInfo ? " close" : "Swinva Payment support"}
      </button>
      {showBankInfo && (
        <div>
          {banks && banks.length > 0 && (
            <form
              style={{
                maxWidth: 800,
                backgroundColor: "#f5f5f8",
                margin: 80,
                borderRadius: 10,
              }}
            >
              <div>
                <label htmlFor="bank" style={{ color: "#352F44" }}>
                  Select your country with currency:
                </label>
                <select
                  id="bank"
                  value={banks.bankId}
                  onChange={handleBankChange}
                  style={{
                    borderWidth: 1.8,
                    borderRadius: 5,
                    width: "97%",
                    padding: "10px",
                  }}
                >
                  <option value="">Select</option>
                  {banks.map((bank) => (
                    <option key={bank.bankId} value={bank.id}>
                      {bank.name}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ margin: 15 }}>
                <label style={{ color: "#352F44" }}>Account Name</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  style={{
                    borderWidth: 1.8,
                    borderRadius: 5,
                  }}
                />
              </div>
              <div style={{ margin: 15 }}>
                <label style={{ color: "#352F44" }}>Phone number</label>
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                  style={{
                    borderWidth: 1.8,
                    borderRadius: 5,
                  }}
                />
              </div>
              <div style={{ margin: 15 }}>
                <label style={{ color: "#352F44" }}>Email</label>
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  style={{
                    borderWidth: 1.8,
                    borderRadius: 5,
                  }}
                />
              </div>
              <button
                onClick={handleSubmit}
                style={{
                  width: 715,
                  margin: 15,
                  borderRadius: 5,
                  fontWeight: 500,
                }}
              >
                {" "}
                {isPosting ? "Saving..." : "Save"}
              </button>
              {postDataError && (
                <small
                  style={{
                    color: "#CE5A67",
                    textAlign: "center",
                    margin: 16,
                  }}
                >
                  Error: {postDataError}
                </small>
              )}
            </form>
          )}
          {banks && banks.length < 1 && (
            <h5>
              Sorry! Payment with swiva is not yet available in {country}.{" "}
              <br />
              We're curretly working with some banks in {country} to make this
              feature available.
            </h5>
          )}
        </div>
      )}
    </div>
  );
};

export default Payment;
