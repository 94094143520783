import React, { useState, useEffect, useContext } from "react";
import ApiService from "../../ApiService";

import "../../styles/Markethome.css";
import Styles from "../../styles/market.module.css";
import NavStyle from "../../styles/Nav.module.css";

import MarketComponentRender from "./MarketContentRendering";
import "./Marketmain.css";
import MarketNavBar from "./MarketNavBar";

const MarketMain = () => {
  const [marketData, setMarketData] = useState();
  const [isloading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [items, setItems] = useState([]);
  const [loadingItem, setLoadingItem] = useState(false);
  const [loadItemsErrMsg, setLoadItemsErrMsg] = useState("");

  useEffect(() => {
    var token = localStorage.getItem("token");
    if (!token) {
      window.location.href = "/login";
    }
    setIsLoading(true);
    setErrorMessage("");
    ApiService.getAsync("Markets")
      .then((resp) => {
        setMarketData(resp);
      })
      .catch((err) => {
        setErrorMessage(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setLoadingItem(true);
    setLoadItemsErrMsg("");
    if (window.chrome && window.chrome.webview) {
      ApiService.getAsync("markets/items")
        .then((resp) => {
          setItems(resp);
        })
        .catch((err) => {
          setLoadItemsErrMsg(err.message);
        })
        .finally(() => {
          setLoadingItem(false);
        });
    }
  }, []);

  const logoutUser = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const [activeTab, setActiveTab] = useState("Update Market");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className={Styles.marketMain}>
      <div className={Styles.marketHeader}>
        <img
          src={require("../../photos/swinva.png")}
          alt=""
          className={NavStyle.logo}
        />

        <MarketNavBar activeTab={activeTab} setActiveTab={setActiveTab} />

        <button
          onClick={() => {
            logoutUser();
          }}
          className={NavStyle.btn}
        >
          sign out
        </button>
      </div>

      <div className={Styles.mainContainer}>
        <MarketComponentRender
          activeTab={activeTab}
          marketData={marketData}
          items={items}
        />
      </div>
    </div>
  );
};

export default MarketMain;
