const countryData = [
    { country: 'Afghanistan', currencyCode: 'AFN' },
    { country: 'Albania', currencyCode: 'ALL' },
    { country: 'Algeria', currencyCode: 'DZD' },
    { country: 'Andorra', currencyCode: 'EUR' },
    { country: 'Angola', currencyCode: 'AOA' },
    { country: 'Antigua & Deps', currencyCode: 'XCD' },
    { country: 'Argentina', currencyCode: 'ARS' },
    { country: 'Armenia', currencyCode: 'AMD' },
    { country: 'Australia', currencyCode: 'AUD' },
    { country: 'Austria', currencyCode: 'EUR' },
    { country: 'Azerbaijan', currencyCode: 'AZN' },
    { country: 'Bahamas', currencyCode: 'BSD' },
    { country: 'Bahrain', currencyCode: 'BHD' },
    { country: 'Bangladesh', currencyCode: 'BDT' },
    { country: 'Barbados', currencyCode: 'BBD' },
    { country: 'Belarus', currencyCode: 'BYN' },
    { country: 'Belgium', currencyCode: 'EUR' },
    { country: 'Belize', currencyCode: 'BZD' },
    { country: 'Benin', currencyCode: 'XOF' },
    { country: 'Bhutan', currencyCode: 'BTN' },
    { country: 'Bolivia', currencyCode: 'BOB' },
    { country: 'Bosnia Herzegovina', currencyCode: 'BAM' },
    { country: 'Botswana', currencyCode: 'BWP' },
    { country: 'Brazil', currencyCode: 'BRL' },
    { country: 'Brunei', currencyCode: 'BND' },
    { country: 'Bulgaria', currencyCode: 'BGN' },
    { country: 'Burkina', currencyCode: 'XOF' },
    { country: 'Burundi', currencyCode: 'BIF' },
    { country: 'Cambodia', currencyCode: 'KHR' },
    { country: 'Cameroon', currencyCode: 'XAF' },
    { country: 'Canada', currencyCode: 'CAD' },
    { country: 'Cape Verde', currencyCode: 'CVE' },
    { country: 'Central African Rep', currencyCode: 'XAF' },
    { country: 'Chad', currencyCode: 'XAF' },
    { country: 'Chile', currencyCode: 'CLP' },
    { country: 'China', currencyCode: 'CNY' },
    { country: 'Colombia', currencyCode: 'COP' },
    { country: 'Comoros', currencyCode: 'KMF' },
    { country: 'Congo', currencyCode: 'XAF' },
    { country: 'Congo {Democratic Rep}', currencyCode: 'CDF' },
    { country: 'Costa Rica', currencyCode: 'CRC' },
    { country: 'Croatia', currencyCode: 'HRK' },
    { country: 'Cuba', currencyCode: 'CUP' },
    { country: 'Cyprus', currencyCode: 'EUR' },
    { country: 'Czech Republic', currencyCode: 'CZK' },
    { country: 'Denmark', currencyCode: 'DKK' },
    { country: 'Djibouti', currencyCode: 'DJF' },
    { country: 'Dominica', currencyCode: 'XCD' },
    { country: 'Dominican Republic', currencyCode: 'DOP' },
    { country: 'East Timor', currencyCode: 'USD' },
    { country: 'Ecuador', currencyCode: 'USD' },
    { country: 'Egypt', currencyCode: 'EGP' },
    { country: 'El Salvador', currencyCode: 'USD' },
    { country: 'Equatorial Guinea', currencyCode: 'XAF' },
    { country: 'Eritrea', currencyCode: 'ERN' },
    { country: 'Estonia', currencyCode: 'EUR' },
    { country: 'Ethiopia', currencyCode: 'ETB' },
    { country: 'Fiji', currencyCode: 'FJD' },
    { country: 'Finland', currencyCode: 'EUR' },
    { country: 'France', currencyCode: 'EUR' },
    { country: 'Gabon', currencyCode: 'XAF' },
    { country: 'Gambia', currencyCode: 'GMD' },
    { country: 'Georgia', currencyCode: 'GEL' },
    { country: 'Germany', currencyCode: 'EUR' },
    { country: 'Ghana', currencyCode: 'GHS' },
    { country: 'Greece', currencyCode: 'EUR' },
    { country: 'Grenada', currencyCode: 'XCD' },
    { country: 'Guatemala', currencyCode: 'GTQ' },
    { country: 'Guinea', currencyCode: 'GNF' },
    { country: 'Guinea-Bissau', currencyCode: 'XOF' },
    { country: 'Guyana', currencyCode: 'GYD' },
    { country: 'Haiti', currencyCode: 'HTG' },
    { country: 'Honduras', currencyCode: 'HNL' },
    { country: 'Hungary', currencyCode: 'HUF' },
    { country: 'Iceland', currencyCode: 'ISK' },
    { country: 'India', currencyCode: 'INR' },
    { country: 'Indonesia', currencyCode: 'IDR' },
    { country: 'Iran', currencyCode: 'IRR' },
    { country: 'Iraq', currencyCode: 'IQD' },
    { country: 'Ireland {Republic}', currencyCode: 'EUR' },
    { country: 'Israel', currencyCode: 'ILS' },
    { country: 'Italy', currencyCode: 'EUR' },
    { country: 'Ivory Coast', currencyCode: 'XOF' },
    { country: 'Jamaica', currencyCode: 'JMD' },
    { country: 'Japan', currencyCode: 'JPY' },
    { country: 'Jordan', currencyCode: 'JOD' },
    { country: 'Kazakhstan', currencyCode: 'KZT' },
    { country: 'Kenya', currencyCode: 'KES' },
    { country: 'Kiribati', currencyCode: 'AUD' },
    { country: 'Korea North', currencyCode: 'KPW' },
    { country: 'Korea South', currencyCode: 'KRW' },
    { country: 'Kosovo', currencyCode: 'EUR' },
    { country: 'Kuwait', currencyCode: 'KWD' },
    { country: 'Kyrgyzstan', currencyCode: 'KGS' },
    { country: 'Laos', currencyCode: 'LAK' },
    { country: 'Latvia', currencyCode: 'EUR' },
    { country: 'Lebanon', currencyCode: 'LBP' },
    { country: 'Lesotho', currencyCode: 'LSL' },
    { country: 'Liberia', currencyCode: 'LRD' },
    { country: 'Libya', currencyCode: 'LYD' },
    { country: 'Liechtenstein', currencyCode: 'CHF' },
    { country: 'Lithuania', currencyCode: 'EUR' },
    { country: 'Luxembourg', currencyCode: 'EUR' },
    { country: 'Macedonia', currencyCode: 'MKD' },
    { country: 'Madagascar', currencyCode: 'MGA' },
    { country: 'Malawi', currencyCode: 'MWK' },
    { country: 'Malaysia', currencyCode: 'MYR' },
    { country: 'Maldives', currencyCode: 'MVR' },
    { country: 'Mali', currencyCode: 'XOF' },
    { country: 'Malta', currencyCode: 'EUR' },
    { country: 'Marshall Islands', currencyCode: 'USD' },
    { country: 'Mauritania', currencyCode: 'MRU' },
    { country: 'Mauritius', currencyCode: 'MUR' },
    { country: 'Mexico', currencyCode: 'MXN' },
    { country: 'Micronesia', currencyCode: 'USD' },
    { country: 'Moldova', currencyCode: 'MDL' },
    { country: 'Monaco', currencyCode: 'EUR' },
    { country: 'Mongolia', currencyCode: 'MNT' },
    { country: 'Montenegro', currencyCode: 'EUR' },
    { country: 'Morocco', currencyCode: 'MAD' },
    { country: 'Mozambique', currencyCode: 'MZN' },
    { country: 'Myanmar {Burma}', currencyCode: 'MMK' },
    { country: 'Namibia', currencyCode: 'NAD' },
    { country: 'Nauru', currencyCode: 'AUD' },
    { country: 'Nepal', currencyCode: 'NPR' },
    { country: 'Netherlands', currencyCode: 'EUR' },
    { country: 'New Zealand', currencyCode: 'NZD' },
    { country: 'Nicaragua', currencyCode: 'NIO' },
    { country: 'Niger', currencyCode: 'XOF' },
    { country: 'Nigeria', currencyCode: 'NGN' },
    { country: 'Norway', currencyCode: 'NOK' },
    { country: 'Oman', currencyCode: 'OMR' },
    { country: 'Pakistan', currencyCode: 'PKR' },
    { country: 'Palau', currencyCode: 'USD' },
    { country: 'Panama', currencyCode: 'PAB' },
    { country: 'Papua New Guinea', currencyCode: 'PGK' },
    { country: 'Paraguay', currencyCode: 'PYG' },
    { country: 'Peru', currencyCode: 'PEN' },
    { country: 'Philippines', currencyCode: 'PHP' },
    { country: 'Poland', currencyCode: 'PLN' },
    { country: 'Portugal', currencyCode: 'EUR' },
    { country: 'Qatar', currencyCode: 'QAR' },
    { country: 'Romania', currencyCode: 'RON' },
    { country: 'Russian Federation', currencyCode: 'RUB' },
    { country: 'Rwanda', currencyCode: 'RWF' },
    { country: 'St Kitts & Nevis', currencyCode: 'XCD' },
    { country: 'St Lucia', currencyCode: 'XCD' },
    { country: 'Saint Vincent & the Grenadines', currencyCode: 'XCD' },
    { country: 'Samoa', currencyCode: 'WST' },
    { country: 'San Marino', currencyCode: 'EUR' },
    { country: 'Sao Tome & Principe', currencyCode: 'STN' },
    { country: 'Saudi Arabia', currencyCode: 'SAR' },
    { country: 'Senegal', currencyCode: 'XOF' },
    { country: 'Serbia', currencyCode: 'RSD' },
    { country: 'Seychelles', currencyCode: 'SCR' },
    { country: 'Sierra Leone', currencyCode: 'SLL' },
    { country: 'Singapore', currencyCode: 'SGD' },
    { country: 'Slovakia', currencyCode: 'EUR' },
    { country: 'Slovenia', currencyCode: 'EUR' },
    { country: 'Solomon Islands', currencyCode: 'SBD' },
    { country: 'Somalia', currencyCode: 'SOS' },
    { country: 'South Africa', currencyCode: 'ZAR' },
    { country: 'South Sudan', currencyCode: 'SSP' },
    { country: 'Spain', currencyCode: 'EUR' },
    { country: 'Sri Lanka', currencyCode: 'LKR' },
    { country: 'Sudan', currencyCode: 'SDG' },
    { country: 'Suriname', currencyCode: 'SRD' },
    { country: 'Swaziland', currencyCode: 'SZL' },
    { country: 'Sweden', currencyCode: 'SEK' },
    { country: 'Switzerland', currencyCode: 'CHF' },
    { country: 'Syria', currencyCode: 'SYP' },
    { country: 'Taiwan', currencyCode: 'TWD' },
    { country: 'Tajikistan', currencyCode: 'TJS' },
    { country: 'Tanzania', currencyCode: 'TZS' },
    { country: 'Thailand', currencyCode: 'THB' },
    { country: 'Togo', currencyCode: 'XOF' },
    { country: 'Tonga', currencyCode: 'TOP' },
    { country: 'Trinidad & Tobago', currencyCode: 'TTD' },
    { country: 'Tunisia', currencyCode: 'TND' },
    { country: 'Turkey', currencyCode: 'TRY' },
    { country: 'Turkmenistan', currencyCode: 'TMT' },
    { country: 'Tuvalu', currencyCode: 'AUD' },
    { country: 'Uganda', currencyCode: 'UGX' },
    { country: 'Ukraine', currencyCode: 'UAH' },
    { country: 'United Arab Emirates', currencyCode: 'AED' },
    { country: 'United Kingdom', currencyCode: 'GBP' },
    { country: 'United States', currencyCode: 'USD'},
    { country: 'Uruguay', currencyCode: 'UYU' },
    { country: 'Uzbekistan', currencyCode: 'UZS' },
    { country: 'Vanuatu', currencyCode: 'VUV' },
    { country: 'Vatican City', currencyCode: 'EUR' },
    { country: 'Venezuela', currencyCode: 'VES' },
    { country: 'Vietnam', currencyCode: 'VND' },
    { country: 'Yemen', currencyCode: 'YER' },
    { country: 'Zambia', currencyCode: 'ZMW' },
    { country: 'Zimbabwe', currencyCode: 'ZWL' }
  ];
  
  export default countryData;
  
  