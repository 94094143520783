import { AuthContext } from "../Contexts/AuthContext";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ApiService from "../ApiService";
import Styles from "../styles/Login.module.css";

const LoginPage = () => {
  const { loginUser } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isResetloading, setIsResetLoading] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetMessage, setResetMessage] = useState("");

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setShowForgotPassword(!showForgotPassword);
  };
  const handleResetPassword = (e) => {
    e.preventDefault();
    if (resetEmail) {
      setIsResetLoading(true);
      ApiService.postWithNoTokenAsync("Users/forget-password", {
        email: resetEmail,
      })
        .then((resp) => {
          localStorage.setItem("email", resetEmail);
          window.location.href = "/reset-password";
        })
        .catch((err) => {
          setResetMessage(err.message);
        })
        .finally(() => {
          setIsResetLoading(false);
        });
    } else setResetMessage("Please enter your email.");
  };
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      if (email && password) {
        const loginData = { email, password };
        const loginUrl = "Users/login";
        setIsLoading(true);
        setErrorMessage("");
        ApiService.postWithNoTokenAsync(loginUrl, loginData)
          .then((response) => {
            localStorage.setItem("token", response.accessToken);
            localStorage.setItem("refreshToken", response.refreshToken);
            const newTokenTTL = Date.now() + (1000 * 60 * 60 - 1000 * 60);
            localStorage.setItem("tokenTTL", newTokenTTL);
            setUser(response);
          })
          .catch((error) => {
            if (error.message.includes("401"))
              setErrorMessage("Email or password is not correct.");
            else setErrorMessage(error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        setErrorMessage("Email and password are required.");
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    if (user) {
      loginUser(user);
      navigate("/market");
    }
  }, [user]);

  const handleNavHome = () => {
    window.location.href = "/";
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <img
          className={Styles.logo}
          src={require("../photos/swinva.png")}
          alt=""
          onClick={handleNavHome}
        />
        <div className={Styles.homeClick} onClick={handleNavHome}>
          <p style={{ textAlign: "center", fontWeight: 600, color: "#fff" }}>
            Home
          </p>
        </div>
      </div>

      <form className={Styles.form}>
        <p className={Styles.formTitle}>Login to your account</p>
        <div>
          <label style={{ color: "#352F44" }}>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email address"
            required
            className={Styles.formInput}
          />
        </div>
        <div>
          <label style={{ color: "#352F44" }}>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="********"
            required
            className={Styles.formInput}
          />
        </div>
        <button
          type="button"
          onClick={handleLogin}
          disabled={isloading}
          className={Styles.btn}
        >
          {isloading ? "Logging in..." : "Login"}
        </button>
        {errorMessage && (
          <small
            style={{
              color: "#CE5A67",
              textAlign: "center",
              margin: 16,
            }}
          >
            Error: {errorMessage}
          </small>
        )}
        <p>
          <button
            type="button"
            onClick={handleForgotPassword}
            style={{
              color: "#007bff",
              cursor: "pointer",
              border: "none",
              background: "none",
              fontWeight: 600,
              fontSize: 16,
              textDecoration: "underline",
            }}
          >
            Forgot Password?
          </button>
        </p>
      </form>
      {showForgotPassword && (
        <form className={Styles.form} onSubmit={handleResetPassword}>
          <label>
            Enter your email to reset password:
            <input
              type="email"
              name="resetEmail"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              className={Styles.formInput}
            />
          </label>
          <button type="submit" className={Styles.btn}>
            {isResetloading ? "Loading" : "Reset Password"}
          </button>
          {resetMessage && (
            <p
              style={{ marginTop: "10px", color: "green", fontWeight: "bold" }}
            >
              {resetMessage}
            </p>
          )}
          {resetMessage && (
            <p
              style={{
                color: "#CE5A67",
                textAlign: "center",
              }}
            >
              Error: {resetMessage}
            </p>
          )}
        </form>
      )}

      <div
        style={{ display: "flex", justifyContent: "space-between", margin: 40 }}
      >
        <small style={{ display: "block", fontWeight: 500 }}>
          &copy; {new Date().getFullYear()} Swinva | All rights reserved
        </small>
        <div style={{ display: "flex" }}>
          <small style={{ fontWeight: 500, marginRight: 40 }}>
            Privacy policy
          </small>
          <small style={{ fontWeight: 500 }}>Terms and conditions</small>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
