import React, { useState } from 'react';

const SwinvaTab = ({ label, onClick, isActive }) => {
  return (
    <div className={`tab ${isActive ? 'active' : ''}`} onClick={onClick}
    style={{ fontWeight: isActive ? 'bold' : 'normal', cursor:'pointer' }}
    >
      {label}
    </div>
  );
};
 
export default SwinvaTab;

