import SwinvaTab from "../SwinvaTab";
import styles from "./Settings.module.css";

const SettingsNavbar = ({ activeTab, setActiveTab }) => {
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div className={styles.sideBar}>
      <SwinvaTab
        label="Secret Key"
        onClick={() => handleTabClick("key-gen")}
        isActive={activeTab === "key-gen"}
      />
      <SwinvaTab
        label="Change Password"
        onClick={() => handleTabClick("change-password")}
        isActive={activeTab === "change-password"}
      />
      <SwinvaTab
        label="Delete Account"
        onClick={() => handleTabClick("delete-account")}
        isActive={activeTab === "delete-account"}
      />
    </div>
  );
};

export default SettingsNavbar;
