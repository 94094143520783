import ChangePassword from "../HomeComponents/ChangePassword";
import MarketSettingsPage from "../SettingsComponents/MarketSettings";
import KeyGen from "./KeyGen";
import PriceTag from "./MarketPriceTag/PriceTag";
import MarketSale from "./MarketSale";
import UpdateMarket from "./UpdateMarket";

const MarketContentRendering = ({ activeTab, marketData, items }) => {
  switch (activeTab) {
    case "Sales":
      return (
        <div>
          {marketData && <MarketSale market={marketData} items={items} />}
        </div>
      );
    case "Update Market":
      return <div>{marketData && <UpdateMarket data={marketData} />}</div>;
    case "Price Tag":
      return (
        <div>
          <PriceTag data={items} />
        </div>
      );
    case "Settings":
      return (
        <div>
         <MarketSettingsPage/>
        </div>
      );
    default:
      return null;
  }
};

export default MarketContentRendering;
