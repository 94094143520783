import {BsReverseBackspaceReverse} from "react-icons/bs"
const PriceTageScheduleTable = ({records, removeRecord}) => {

    return ( <div>
            <table>
                <thead>
                    <tr>
                        <th>Sn</th>
                        <th>Barcode</th>
                        <th>Name</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {records.map((item, indx)=>{
                    return(
                        <tr key={item.barcode}>
                            <td>{indx+1}</td>
                            <td>{item.barcode}</td>
                            <td>{item.name}</td>
                            <td>{item.price}</td>
                            <td className="fit">
                            <span className="actions">
                                <BsReverseBackspaceReverse
                                className="delete-btn"
                                onClick={() => removeRecord(indx)}
                                />
                            </span>
                        </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
    </div> );
}
 
export default PriceTageScheduleTable;