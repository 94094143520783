import SwinvaTab from "../SwinvaTab";
import NavStyles from "../../styles/Nav.module.css";
import { useState } from "react";

const MarketNavBar = ({ activeTab, setActiveTab }) => {
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <div className={NavStyles.links}>
        <SwinvaTab
          label="Sales"
          onClick={() => handleTabClick("Sales")}
          isActive={activeTab === "Sales"}
        />
        <SwinvaTab
          label="Update Market"
          onClick={() => handleTabClick("Update Market")}
          isActive={activeTab === "Update Market"}
        />
        {/* <SwinvaTab
        label="Price Tag"
        onClick={() => handleTabClick("Price Tag")}
        isActive={activeTab === "Price Tag"}
      /> */}
        <SwinvaTab
          label="Settings"
          onClick={() => handleTabClick("Settings")}
          isActive={activeTab === "Settings"}
        />
      </div>
      <div className={NavStyles.hamburgerMenuContainer}>
        <div className={NavStyles.hamburgerMenu} onClick={toggleMenu}>
          <div
            className={`${NavStyles.bar} ${isOpen ? NavStyles.open : ""}`}
          ></div>
          <div
            className={`${NavStyles.bar} ${isOpen ? NavStyles.open : ""}`}
          ></div>
        </div>
      </div>
      {isOpen ? (
        <div className={NavStyles.mobileMenu}>
          <ul className={NavStyles.mobileNav}>
            <li>
              <SwinvaTab
                label="Sales"
                onClick={() => handleTabClick("Sales")}
                isActive={activeTab === "Sales"}
              />
            </li>
            <li>
              <SwinvaTab
                label="Update Market"
                onClick={() => handleTabClick("Update Market")}
                isActive={activeTab === "Update Market"}
              />
            </li>
            {/* <li>
              <SwinvaTab
                label="Price Tag"
                onClick={() => handleTabClick("Price Tag")}
                isActive={activeTab === "Price Tag"}
              />
            </li> */}
            <li>
              <SwinvaTab
                label="Settings"
                onClick={() => handleTabClick("Settings")}
                isActive={activeTab === "Settings"}
              />
            </li>
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MarketNavBar;
