import Nav from "../Components/HomeComponents/Nav";
import DeveloperIntro from "../Components/DeveloperCompoenent/DeveloperIntro";
import UpsertItemsAPI from "../Components/DeveloperCompoenent/UpsertItemsAPI";
import DeleteItemsAPI from "../Components/DeveloperCompoenent/DeleteItemsAPI";
import SecretKeyDoc from "../Components/DeveloperCompoenent/SecretKeyDoc";
import ReadShoppedItems from "../Components/DeveloperCompoenent/ReadShoppedItems";
import devStyles from "../Components/DeveloperCompoenent/DevelopersStyles.module.css";

const Developers = () => {
  return (
    <div>
      <div>
        <Nav />
      </div>
      <div style={{ width: "100%", display: "flex" }}>
        <div className={devStyles.links}>
          <a className={devStyles.sideBarA} href="#dev-intro">
            Introduction
          </a>
          <a className={devStyles.sideBarA} href="#upsert">
            Upsert Items API
          </a>
          <a className={devStyles.sideBarA} href="#dele">
            Delete Items API
          </a>
          <a className={devStyles.sideBarA} href="#sec-key">
            Secret Key
          </a>
          <a className={devStyles.sideBarA} href="#read-mob-itms">
            Read Shopped-items
          </a>
        </div>
        <div className={devStyles.container}>
          <section id="dev-intro">
            <DeveloperIntro />
          </section>
          <section id="upsert">
            <UpsertItemsAPI />
          </section>
          <section id="dele">
            <DeleteItemsAPI />
          </section>
          <section id="sec-key">
            <SecretKeyDoc />
          </section>
          <section id="read-mob-itms">
            <ReadShoppedItems />
          </section>
        </div>
      </div>
    </div>
  );
};

export default Developers;
