import axios from "axios";
import { showToast } from "./Components/HomeComponents/ToastNotification";

class ApiService {
  constructor(baseURL = process.env.REACT_APP_API_URL) {
    this.axiosInstance = axios.create({
      baseURL,
    });
    this.setUpInterceptors();
  }

  setUpInterceptors() {
    this.axiosInstance.interceptors.response.use(
      this.handleSuccessResponse.bind(this),
      this.handleErrorResponse.bind(this)
    );
  }

  handleSuccessResponse(response) {
    const { config, status } = response;
    const { method, url } = config;

    if (
      method !== "get" &&
      !url.includes("users/refresh-token") &&
      !url.includes("Users/login")
    ) {
      if (status >= 200 && status < 300) {
        showToast("Success!", "success");
      }
    }

    return response;
  }

  handleErrorResponse(error) {
    if (error.response) {
      const { status, data } = error.response;

      if (status !== 401) {
        const errorMessage = data || `API Error Status (${status})`;
        showToast(errorMessage, "error");
      }
    } else if (error.request) {
      console.error("No API response received");
    } else {
      console.error(`Error setting up the API request: ${error.message}`);
    }

    return Promise.reject(error);
  }

  async getConfig() {
    await this.RefreshToken();
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return config;
  }

  async RefreshToken() {
    const ttl = parseInt(localStorage.getItem("tokenTTL"));
    const currentTime = Date.now() / (1000 * 60);

    if (ttl < currentTime) {
      try {
        const token = localStorage.getItem("token");
        const refreshToken = localStorage.getItem("refreshToken");
        const resp = await this.axiosInstance
          .post("users/refresh-token", {
            accessToken: token,
            refreshToken: refreshToken,
          })
          .then((resp) => {
            localStorage.setItem("accessToken", resp.data.accessToken);
            localStorage.setItem("refreshToken", resp.data.refreshToken);
            const newTokenTTL = Date.now() + (1000 * 60 * 60 - 1000 * 60);
            localStorage.setItem("tokenTTL", newTokenTTL);
          })
          .catch((err) => {
            window.location.href = "/login";
          });
      } catch (error) {
        showToast(error, "error");
        window.location.href = "/login";
      }
    }
  }

  async getAsync(url) {
    const config = await this.getConfig();
    const response = await this.axiosInstance.get(url, config);
    return response.data;
  }

  async postAsync(url, data) {
    const config = await this.getConfig();
    const response = await this.axiosInstance.post(url, data, config);
    return response.data;
  }
  async postWithNoTokenAsync(url, data) {
    const response = await this.axiosInstance.post(url, data);
    return response.data;
  }

  async patchAsync(url, data) {
    const config = await this.getConfig();
    const response = await this.axiosInstance.patch(url, data, config);
    return response.data;
  }

  async putAsync(url, data) {
    const config = await this.getConfig();
    const response = await this.axiosInstance.put(url, data, config);
    return response.data;
  }

  async deleteAsync(url) {
    const config = await this.getConfig();
    const response = await this.axiosInstance.delete(url, config);
    return response.data;
  }

  async getPaginatedDataAsync(url, page, pageSize) {
    const config = await this.getConfig();
    const response = await this.axiosInstance.get(
      `${url}?page=${page}&pageSize=${pageSize}`,
      config
    );
    return response.data;
  }

  async getDataWithFiltersAsync(url, filters) {
    const response = await this.axiosInstance.get(url, {
      params: filters,
    });
    return response.data;
  }

  async uploadFileAsync(url, file) {
    const config = await this.getConfig();
    const formData = new FormData();
    formData.append("file", file);
    const response = await this.axiosInstance.put(url, formData, config);

    return response.data;
  }
}

export default new ApiService();
