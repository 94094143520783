import { createContext, useState } from "react";

export const UserContext=createContext()
const UseContextProvider = (props) => {
    const [logo,setLogo]=useState("")
    const setMarketLogo=(byteArray)=>{
            const base64String = btoa(String.fromCharCode(byteArray));
            setLogo(base64String)
    }
    return ( 
        <UserContext.Provider value={{logo, setMarketLogo}}>
            {props.children}
        </UserContext.Provider>
     );
}
 
export default UseContextProvider;