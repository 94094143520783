import { useEffect, useRef, useState } from 'react';

export const useNativeApi = (senderTopic, receiverTopic) => {
  const [response, setResponse] = useState(null);
  const callbackRef = useRef(null);
  const queryRef = useRef(null);

  const subscription = (query) => {
    queryRef.current = query;

    const then = (callback) => {
      callbackRef.current = callback;
      if (window.chrome && window.chrome.webview){
        window.chrome.webview.addEventListener('message', messageSubscriber);

        if (queryRef.current) {
          send(senderTopic, queryRef.current);
        }
  
        return () => {
          window.chrome.webview.removeEventListener('message', messageSubscriber);
        };
      };
      }
     

    return { then };
  };

  const messageSubscriber = (arg) => {
    if (!arg.data.topic) return;

    if (arg.data.topic === receiverTopic) {
      if (callbackRef.current) {
        callbackRef.current(arg.data.payload);
        callbackRef.current = null;
      }
    }
  };

  const send = (topic, payload) => {
    const message = {
      payload,
      topic,
    };
    window.chrome.webview.postMessage(message);
  };

  useEffect(() => {
    return subscription;
  }, [senderTopic, receiverTopic]);

  return { send: subscription };
};
