import React, { useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';

const SalesNotification = ({ marketId, AddNewPurchase }) => {
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    let connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL}/purchaseHub`)
      .withAutomaticReconnect()
      .build();

    const startConnection = () => {
      connection
        .start()
        .then(() => {
          setIsConnected(true);
          console.log('SignalR Connected');

          connection.invoke('JoinMarketGroup', marketId).catch((error) => {
            console.error('Error joining group:', error);
          });

          connection.on('PurchaseNotification', (purchase) => {
            AddNewPurchase(purchase);
            console.log('New Purchase:', purchase);
          });
        })
        .catch((error) => {
          console.error('SignalR Connection Error:', error);
          setTimeout(startConnection, 5000); // Attempt reconnect after 5 seconds
        });
    };

    startConnection();

    connection.onclose(() => {
      setIsConnected(false);
      console.log('SignalR Connection Closed. Reconnecting...');
      setTimeout(startConnection, 5000); 
    });

    return () => {
      connection.stop();
    };
  }, [marketId]);

  return (
    <div>
      {isConnected ? (
        <p>Connected to SignalR</p>
      ) : (
        <p>Connecting to SignalR...</p>
      )}
    </div>
  );
};

export default SalesNotification;
