import { useState } from "react";
import PriceTagModal from "./PriceTagModal";
import PriceTageTable from "./PriceTageTable";
import PriceTageScheduleTable from "./PriceTagScheduleTable";
import "./Pricetag.css"
import ImportPriceTage from "./ImportPriceTag";
import * as XLSX from "xlsx";
import useScanDetection from "use-scan-detection-react18";
import ApiService from "../../../ApiService";

const PriceTag   = ({data}) => {
    const[modelOpen, setModalOpen] = useState(false);
    const [ItemsToAdd, setItemsToAdd]=useState([])
    const [ItemsToUpdate, setItemsToUpdate]=useState([])
    const [itemsToDelete, setItemsToDelete]=useState([])
    const [importedItems, setImportedItems]=useState([])
    const [barcode, setBardCode]=useState("")
    const [itemName, setItemName]=useState("")
    const [price, setPrice]=useState(0.00)
    const [IsEdit, setIsEdit]=useState("")


    const [isAdding, setIsAdding]=useState(false)
    const [addError, setAddError]=useState("")
    const [isUpdating, setIsUpdating]=useState(false)
    const [updateError, setUpdateError]=useState("")
    const [isDeleting, setIsDeleting]=useState(false)
    const [deleteError, setDeleteError]=useState("")
    const [isAddingImport, setIsAddingImport]=useState(false)
    const [addImportError, setAddImportError]=useState("")

    const handleAddItems= async ()=>{
        if(ItemsToAdd.length>0){
          {
            setIsAdding(true)
            setAddError("")
            ApiService.postAsync("markets/upsert-items",{items:ItemsToAdd})
            .then(resp=>{
                setItemsToAdd([])
            }).catch(err=>{
                setAddError(err.message)
            }).finally(()=>{
                setIsAdding(false)
            })
          }
        }
    }
    
    const handleUpdateItems= async ()=>{
        if(ItemsToUpdate.length>0){
          {
            setIsUpdating(true)
            setUpdateError("")
            ApiService.postAsync("markets/upsert-items",{items:ItemsToUpdate})
            .then(resp=>{
                setItemsToUpdate([])
            }).catch(err=>{
                setUpdateError(err.message)
            }).finally(()=>{
                setIsUpdating(false)
            })
          }
        }
    }

    const handleDeleteItems= async ()=>{
        let barcodes=[]
        if(itemsToDelete.length>0){
            itemsToDelete.forEach(item => {
                barcodes.push(item.barcode)
            });
            setIsDeleting(true)
            setDeleteError("")
            ApiService.postAsync("markets/delete-items",{barcodes})
            .then(resp=>{
                setItemsToDelete([])
            }).catch(err=>{
                setDeleteError(err.message)
            }).finally(()=>{
                setIsDeleting(false)
            })
          
        }
    }

    const handleCommands=(item, cmd=null)=>{
        const { barcode } = item; 

        if(!IsEdit && !cmd)
            {
                const isItemInToAdd = ItemsToAdd.some(existingItem => existingItem.barcode === barcode);
                if(!isItemInToAdd)
                    {
                        const isItems = data.some(existingItem => existingItem.barcode === barcode);
                        if(!isItems)
                            setItemsToAdd([...ItemsToAdd,item])
                        else
                            {
                                alert("Item with the same barcode is alread in your price tag list.") 
                                return;
                            }
                    }
                else
                  {
                    alert("Item with the same barcode is alread in the add schedule list.")
                    return;
                  }
            }
        else if(IsEdit && !cmd)
            {
                setItemsToUpdate([...ItemsToUpdate,item])
                setIsEdit(false)
            } 
        else if(cmd)
            {
                const isItemInToDelete = itemsToDelete.some(existingItem => existingItem.barcode === barcode);
                if(!isItemInToDelete){
                    setItemsToDelete([...itemsToDelete,item])
                }else
                {
                    alert("Item with the same barcode is alread in the delete schedule list.");
                    return;
                }
            }
        setModalOpen(false);
    }

    const isBarcode = (inputString) => {
        const regex = /^[A-Za-z0-9\-\.\ \$\%\*\+\/]+$/;
        return regex.test(inputString);
      }; 

    useScanDetection({
        onComplete:(code)=>{
            
            setModalOpen(false)
            if(!isBarcode(code)) return
            const existItem=data.find(item=>item.barcode===code)
            if(existItem){
                setIsEdit(true)
                setBardCode(existItem.barcode)
                setItemName(existItem.name)
                setPrice(existItem.price)
                setModalOpen(true)
            }else{
                setIsEdit(false)
                setBardCode(code)
                setItemName("")
                setPrice(0.00)
                setModalOpen(true)
            }
            
        },
        minLength:4
      })

    const editItem = (item) => {
        const isItemInToUpdate = ItemsToUpdate.some(existingItem => existingItem.barcode === item.barcode);
        if(!isItemInToUpdate){
            setModalOpen(false);
            setIsEdit(true)
            setBardCode(item.barcode)
            setItemName(item.name)
            setPrice(item.price)
            setModalOpen(true);
            
        }else
        alert("Item with the same barcode is alread in the update schedule list.");
       
    }
    const addNewItem=()=>{
        setIsEdit(false)
        setBardCode("")
        setItemName("")
        setPrice(0.00)
        setModalOpen(true)
    }

    const removedItemToAdd=(targetIndx)=>{
        setItemsToAdd(ItemsToAdd.filter((_,indx)=> indx!==targetIndx))
    }
    const removedItemToUpdate=(targetIndx)=>{
        setItemsToUpdate(ItemsToUpdate.filter((_,indx)=> indx!==targetIndx))
    }
    const removedItemToDelete=(targetIndx)=>{
        setItemsToDelete(itemsToDelete.filter((_,indx)=> indx!==targetIndx))
    }

    const handleFileUpload = (e) => {
        const reader = new FileReader();
        reader.readAsBinaryString(e.target.files[0]);
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const parsedData = XLSX.utils.sheet_to_json(sheet);
          setImportedItems(parsedData)
        };
      }

    const saveImported= async()=>{
        if(importedItems.length<1)
            alert("invalid data imported.")
        else
        {
            // validate and filter existing records
            setIsAddingImport(true)
            setAddImportError("")
            ApiService.postAsync("markets/upsert-items",{items:importedItems})
            .then(resp=>{
                setImportedItems([])
                alert("saved")
            }).catch(err=>{
                setAddImportError(err.message)
            }).finally(()=>{
                setIsAddingImport(false)
            })
        }
    }
    return ( 
        <div className="price-tag">
            <div className="schedule-price-tag">
               {ItemsToAdd.length>0 &&
                <div className="schedule">
                <h2>Add Items schedule list</h2>
                <h5>On submit, the following items will be added to your price tag list</h5>
                <PriceTageScheduleTable records={ItemsToAdd} removeRecord={removedItemToAdd}/>
                {addError && (
                            <small
                            style={{
                                color: "#CE5A67",
                                textAlign: "center",
                                margin: 16,
                            }}
                            >
                            Error: {addError}
                            </small>
                        )}
                <div className="schedule-btns">
                        <button onClick={handleAddItems}>{isAdding ? "Submitting..." : "Submit"}
                        </button>
                        
                        <button onClick={()=>setItemsToAdd([])}>Abort</button>
                    </div>
            </div>
               }
                {ItemsToUpdate.length>0 &&
                <div className="schedule">
                    <h2>Update Items shedule list</h2>
                    <h5>On submit, the following items will be Updated in your price tag list</h5>
                    <PriceTageScheduleTable records={ItemsToUpdate} removeRecord={removedItemToUpdate}/>
                    {updateError && (
                            <small
                            style={{
                                color: "#CE5A67",
                                textAlign: "center",
                                margin: 16,
                            }}
                            >
                            Error: {updateError}
                            </small>
                        )}
                    <div className="schedule-btns">
                        <button onClick={handleUpdateItems}>{isUpdating ? "Submitting..." : "Submit"}
                        </button>
                       
                        <button onClick={()=>setItemsToUpdate([])}>Abort</button>
                    </div>
                </div>
                }
                {itemsToDelete.length>0 &&
                <div className="schedule">
                    <h2>Delete Items shedule list</h2>
                    <h5>On submit, the following items will be Permanently removed from your price tag list</h5>
                    <PriceTageScheduleTable records={itemsToDelete} removeRecord={removedItemToDelete}/>
                    {deleteError && (
                            <small
                            style={{
                                color: "#CE5A67",
                                textAlign: "center",
                                margin: 16,
                            }}
                            >
                            Error: {deleteError}
                            </small>
                        )}
                    <div className="schedule-btns">
                        <button onClick={handleDeleteItems}>{" "}
                        {isDeleting ? "Submitting..." : "Submit"}
                        </button>
                        
                        <button onClick={()=>setItemsToDelete([])}>Abort</button>
                    </div>
                </div>
                }
            </div>
            <div className="price-tag-list-head">
                <h2>Items Price Tag</h2>
                <p style={{fontSize:9}}>when importing, the first colum is "Barcode", second column is "Name" and third column is "Price"</p>
                <div style={{display:"flex", alignItems:"center", gap:10}}>
                   {!importedItems.length> 0 && <button onClick={() => addNewItem()} className="btn"
                    > Add new item</button>} 
                    {importedItems.length<1 &&<div className="file-input">
                    <label htmlFor="excel-import" className="custom-file-input">
                        Import from Excel
                    </label>
                    <input
                        id="excel-import"
                        name="excel-import"
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileUpload}
                    />
                    </div>}
                </div>
               
                {importedItems.length> 0 && <div>
                    
                <div style={{display:"flex", gap:10, justifyContent:"center"}}>
                    <button className="btn"
                        onClick={saveImported}> {" "}
                        {isAddingImport ? "Saving..." : "Save"}
                    </button>
                
                    <button className="btn"
                        onClick={()=>setImportedItems([])}>Abort</button>
                </div>
                {addImportError && (
                <small
                  style={{
                    color: "#CE5A67",
                    textAlign: "center",
                    margin: 16,
                  }}
                >
                  Error: {addImportError}
                </small>
              )}
                <ImportPriceTage importedItems={importedItems}/>
                </div>}
            </div>
            <div className="pric-tag-main-table">
                {data && importedItems.length<1 && <PriceTageTable records={data} editItem={editItem} handleCommands={handleCommands}/>}
                
            </div>
           {modelOpen && (
            <PriceTagModal
                closeModal={()=>{
                    setModalOpen(false);
                }}
                handleCommands={handleCommands}
                barcode={barcode}
                setBarcode={setBardCode}
                name={itemName}
                setName={setItemName}
                price={price}
                setPrice={setPrice}
                isUpdate={IsEdit}
            />
           )}
        </div>
     );
}
 
export default PriceTag ;
