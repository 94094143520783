import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import styles from "../../styles/Nav.module.css";
import { AuthContext } from "../../Contexts/AuthContext";

function Nav() {
  const { token, logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const handleNavigate = () => {
    window.location.href = "/login";
  };
  const handleNavHome = () => {
    window.location.href = "/";
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav className={styles.navbar}>
      <div className={styles.divLinks}>
        <img
          className={styles.logo}
          src={require("../../photos/swinva.png")}
          alt=""
          onClick={handleNavHome}
        />
        <ul className={styles.links}>
          {/* <NavLink className={styles.linkItem} to="/Developers">
            Developers
          </NavLink> */}
          <NavLink className={styles.linkItem} to="/Partners">
            Partners
          </NavLink>
          <NavLink className={styles.linkItem} to="/Contact">
            Contact
          </NavLink>
          <NavLink className={styles.linkItem} to="/About">
            About
          </NavLink>
        </ul>
      </div>
      <div className={styles.hamburgerMenuContainer}>
        <div className={styles.hamburgerMenu} onClick={toggleMenu}>
          <div className={`${styles.bar} ${isOpen ? styles.open : ""}`}></div>
          <div className={`${styles.bar} ${isOpen ? styles.open : ""}`}></div>
        </div>
      </div>
      {isOpen ? (
        <div className={styles.mobileMenu}>
          <ul className={styles.mobileNav}>
            {/* <NavLink className={styles.mobileLinkItem} to="/Developers">
              Developers
            </NavLink> */}
            <NavLink className={styles.mobileLinkItem} to="/Partners">
              Partners
            </NavLink>
            <NavLink className={styles.mobileLinkItem} to="/Contact">
              Contact
            </NavLink>
            <NavLink className={styles.mobileLinkItem} to="/About">
              About
            </NavLink>
          </ul>
        </div>
      ) : (
        ""
      )}
      <div className={styles.smallScreenBtn}>
        {!token ? (
          <div className={styles.btn} onClick={handleNavigate}>
            <p>Sign in</p>
          </div>
        ) : (
          <div className={styles.btn} onClick={() => logoutUser()}>
            <p>Sign out</p>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Nav;
