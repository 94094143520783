import { useEffect, useState } from "react";
import ApiService from "../ApiService";
import { showToast } from "../Components/HomeComponents/ToastNotification";
import Styles from "../styles/PasswordReset.module.css";

const PasswordReset = () => {
  const [pin, setPin] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPinValid, setIsPinValid] = useState(false);
  const [isloading, setIsLoading] = useState(false);

  useEffect(() => {
    setEmail(localStorage.getItem("email"));
  }, []);

  const handleConfirmPin = (e) => {
    e.preventDefault();
    if (pin.length === 6) {
      setIsLoading(true);
      ApiService.postWithNoTokenAsync("Users/recover-password", {
        email: email,
        recoveryPin: pin,
      })
        .then((resp) => {
          setIsPinValid(true);
        })
        .catch((err) => {})
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      showToast("Invalid pin", "error");
    }
  };

  const handleSetNewPassword = (e) => {
    e.preventDefault();
    if (password && password === confirmPassword) {
      setIsLoading(true);
      ApiService.postWithNoTokenAsync("Users/set-new-password", {
        email: email,
        password: password,
        recoveryPin: pin,
      })
        .then((resp) => {
          showToast("Password reset is successful!", "success");
          setIsLoading(false);
          localStorage.removeItem("email");
          window.location.href = "/login";
        })
        .catch((err) => {
          showToast(err.message, "error");
        })
        .finally(() => setIsLoading(false));
    }
  };
  const handleNavHome = () => {
    window.location.href = "/";
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginRight: 32,
          padding: 0,
          height: 80,
        }}
      >
        <img
          className={Styles.logo}
          src={require("../photos/swinva.png")}
          alt=""
          onClick={handleNavHome}
        />
        <div className={Styles.homeClick} onClick={handleNavHome}>
          <p style={{ textAlign: "center", fontWeight: 600, color: "#fff" }}>
            Home
          </p>
        </div>
      </div>
      <div className={Styles.container}>
        <h2 style={{ textAlign: "center" }}>Reset password</h2>
        {!isPinValid && (
          <form className={Styles.form}>
            <h3>Enter the password reset pin sent to your email </h3>
            <input
              required={true}
              type="text"
              placeholder="Password recovery pin"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              className={Styles.formInput}
            />
            <button onClick={handleConfirmPin} className={Styles.btn}>
              {isloading ? "submitting ..." : "submit"}
            </button>
          </form>
        )}
        {isPinValid && (
          <form className={Styles.form}>
            <h2>Set new password</h2>
            <div>
              <label>New Password</label>
              <input
                type="password"
                placeholder="new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={Styles.formInput}
              />
            </div>
            <div>
              <label>Confirm Password</label>
              <input
                type="password"
                placeholder="new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={Styles.formInput}
              />
            </div>
            <button onClick={handleSetNewPassword} className={Styles.btn}>
              {isloading ? "submitting ..." : "submit"}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default PasswordReset;
