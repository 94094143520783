import { useState } from "react";
import ApiService from "../../ApiService";
import { showToast } from "../HomeComponents/ToastNotification";
import Styles from "../../styles/UpdateMarket.module.css";
import "./uploadlogo.css";

const UploadLogo = () => {
  const [file, setFile] = useState();
  const [imagePreview, setImagePreview] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [uploading, setUploading] = useState(false);

  const validImageExtensions = [
    "png",
    "jpeg",
    "webp",
    "bmp",
    "pbm",
    "tga",
    "gif",
    "tiff",
  ];

  const saveFile = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (validImageExtensions.includes(fileExtension)) {
        setFile(selectedFile);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(selectedFile);
      } else {
        alert(
          "Invalid image. Image must be any of this: png, jpeg, webp, bmp, tga, gif, pbm,tiff"
        );
        e.target.value = null;
        setFile(null);
      }
    }
  };

  const uploadFile = async (e) => {
    if (file) {
      setUploading(true);
      ApiService.uploadFileAsync("Markets/logo", file)
        .then((resp) => {
          showToast("uploaded");
        })
        .catch((error) => {
          if (error.status === 401) {
            window.location.href = "/login";
            return;
          }
          showToast("Error uploading file:", "error");
        })
        .finally(() => setUploading(false));
    } else {
      showToast("No file selected.", "error");
    }
  };

  const cancleUpload = () => {
    setFile(null);
    setImagePreview(null);
  };

  return (
    <div className={Styles.uploadContainer}>
      <div className={Styles.uploadingContainer}>
        {/* {!imagePreview && imageData && <img src={imageData} alt="Image" />} */}
        {imagePreview && (
          <img
            src={imagePreview}
            alt="Preview"
            style={{ width: 150, height: 70 }}
          />
        )}
        <div style={{ display: "flex", alignContent: "start", gap: 25 }}>
          {file && (
            <input
              type="button"
              value="upload"
              onClick={uploadFile}
              className={Styles.inputFileUpload}
            />
          )}
          {file && (
            <input
              type="button"
              value="Cancle"
              onClick={cancleUpload}
              className={Styles.inputFileUpload}
            />
          )}
        </div>
        <div className={Styles.uploadClick}>
          <label htmlFor="market-logo" className="custom-file-input">
            {uploading ? "Uploading" : "Upload market photo"}
          </label>
          <input
            type="file"
            name="market-logo"
            id="market-logo"
            onChange={(e) => saveFile(e)}
            style={{ fontSize: 1, width: 0 }}
          />
        </div>
      </div>
      <div className={Styles.uploadIndtruction}>
        upload your market photo that will be visible to customers on the mobile
        application
      </div>
    </div>
  );
};

export default UploadLogo;
