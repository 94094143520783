import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Contexts/AuthContext";
import styles from "../styles/MarketActivation.module.css";
import ApiService from "../ApiService";

const MarketActivationPage = () => {
  const { loginUser } = useContext(AuthContext);
  const [user, setUser] = useState(null);
  const [isPosting, setIsPosting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const handleCreateMarket = async (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      setIsPosting(true);
      setErrorMessage("");
      ApiService.postAsync("Markets", { email, password })
        .then((resp) => {
          localStorage.setItem("token", resp.accessToken);
          localStorage.setItem("refreshToken", resp.refreshToken);
          const newTokenTTL = Date.now() + (1000 * 60 * 60 - 1000 * 60);
          localStorage.setItem("tokenTTL", newTokenTTL);
          setUser(resp);
        })
        .catch((err) => {
          setErrorMessage(err.message);
        })
        .finally(setIsPosting(false));
    } else {
      setErrorMessage("Password and Confirm Password must be the same.");
    }
  };

  useEffect(() => {
    if (user) {
      loginUser(user);
      navigate("/market");
    }
  }, [user]);

  return (
    <div className={styles.container}>
      <p className={styles.bigText}>Register your Market today</p>

      <div className={styles.marketBackground}>
        <div className={styles.formContainer}>
          <form className={styles.form}>
            <div style={{ margin: 15 }}>
              <label style={{ color: "#fff" }}>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Email address"
                style={{
                  borderWidth: 1.8,
                  borderRadius: 5,
                }}
              />
            </div>
            <div style={{ margin: 15 }}>
              <label style={{ color: "#fff" }}>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="********"
                style={{
                  borderWidth: 1.8,
                  borderColor: "#4F4A45",
                  borderRadius: 5,
                }}
              />
            </div>
            <div style={{ margin: 15 }}>
              <label style={{ color: "#fff" }}>Confirm Password</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                placeholder="********"
                style={{
                  borderWidth: 1.8,
                  borderColor: "#4F4A45",
                  borderRadius: 5,
                }}
              />
            </div>
            <button className={styles.btn} onClick={handleCreateMarket}>
              {isPosting ? "Creating ..." : "Create"}
            </button>
            {errorMessage && (
              <small
                style={{
                  color: "#FF004D",
                  textAlign: "center",
                  marginLeft: 15,
                }}
              >
                Error: {errorMessage}
              </small>
            )}
          </form>
          <p className={styles.formText}>
            Please ensure that your inventory software supports Swinva. If
            Swinva support is not available yet, kindly reach out to your
            software provider and request this feature. It's free to add.
          </p>
        </div>
        <p className={styles.info}>
          Ignite Your Success with the Unyielding Power of Cutting-Edge Stay
          Ahead, Stay Relevant, Stay Unstoppable.
        </p>
      </div>
    </div>
  );
};

export default MarketActivationPage;

{
  /* <div>
        <p style={styles.bigText}>Register your Market today</p>
      </div> */
}
{
  /* <img
        className="market-image"
        src={require("../photos/market-images1.avif")}
        alt="image"
        style={{ height: 600, width: 600 }}
      /> */
}
{
  /* <div>
                        <label htmlFor="apps">
                            Select your Inventory management system.
                        </label>
                        <select id="apps" onChange={handleAppChange} value={appId}>
                            <option value={-1}>Select default</option>
                            {inventoryApps.map(app => (
                            <option key={app.id} value={app.id}>
                                {app.name}
                            </option>
                            ))}
                        </select>
                    </div> */
}

{
  /* <p className={styles.smallText}>
If you are experiencing issues with your inventory management system
and believe it lacks support for Swinva, I recommend reaching out to
the support team or the developers of your inventory management
system.
</p> */
}
