import AccountDeletion from "../HomeComponents/AccountDeletion";
import ChangePassword from "../HomeComponents/ChangePassword";
import KeyGen from "../Market/KeyGen";

const SettingsRendering = ({ activeTab }) => {
  switch (activeTab) {
    case "key-gen":
      return <KeyGen />;
    case "change-password":
      return <ChangePassword />;
    case "delete-account":
      return <AccountDeletion />;
    default:
      return null;
  }
};

export default SettingsRendering;
