const NotFoundPage = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p style={{ fontWeight: 700, fontSize: 40, textAlign: "center" }}>
        Page not found
      </p>
    </div>
  );
};

export default NotFoundPage;
