import React, { useState } from "react";
import ApiService from "../../ApiService";
import Styles from "../../Components/SettingsComponents/Settings.module.css";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPosting, setIsPosting] = useState(false);
  const [postErr, setPostErr] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      setIsPosting(true);
      setPostErr("");
      ApiService.putAsync("Users/change-password", {
        oldPassword: currentPassword,
        newPassword: newPassword,
      })
        .then((resp) => {
          alert("success");
        })
        .catch((err) => {
          setPostErr(err.message);
        })
        .finally(() => {
          setIsPosting(false);
        });
    } else {
      alert("New password must be same as confirm password.");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className={Styles.form}>
        <p className={Styles.formTitle}>Change Password</p>
        <div>
          <label style={{ color: "#352F44" }}>Current Password:</label>
          <input
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
            className={Styles.formInput}
          />
        </div>
        <div>
          <label style={{ color: "#352F44" }}>New Password:</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            className={Styles.formInput}
          />
        </div>
        <div>
          <label style={{ color: "#352F44" }}>Confirm New Password:</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className={Styles.formInput}
          />
        </div>
        <button type="submit" className={Styles.btn}>
          {isPosting ? "Saving..." : "Change Password"}
        </button>
        {postErr && (
          <small
            style={{
              color: "#CE5A67",
              textAlign: "center",
              margin: 15,
            }}
          >
            Error: {postErr}
          </small>
        )}
      </form>
    </div>
  );
};

export default ChangePassword;
