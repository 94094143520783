import React from "react";
import Nav from "../Components/HomeComponents/Nav";
import styles from "../Components/DeveloperCompoenent/DevelopersStyles.module.css";
import bgStyles from "../styles/Contact.module.css";

const Partners = () => {
  const handleReachUs = () => {
    window.location.href = "/contact";
  };
  return (
    <div className={bgStyles.container}>
      <Nav />
      <div className={styles.section}>
        <h1 className={styles.titleHeading}>PARTNER WITH SWINVA TODAY</h1>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "space-evenly",
              justifyContent: "center",
              height: "30vh",
              backgroundColor: "whitesmoke",
              marginTop: 40,
              maxWidth: 800,
              padding: 15,
            }}
          >
            <h2 className={styles.heading} style={{ textAlign: "center" }}>
              Our doors are wide open for collaboration and partnership.
            </h2>
            <h4 style={{ textAlign: "center" }}>
              Please don't hesitate to reach out to us at info@swinva.com or via
              our{" "}
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={handleReachUs}
              >
                contact page.
              </span>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
