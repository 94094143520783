import { useState } from "react";
import "./Pricetagmodal.css";

const PriceTagModal = ({ closeModal, handleCommands, barcode, setBarcode, name, setName, price, setPrice, isUpdate }) => {
  const [errors, setErrors] = useState("");

  const validateForm = () => {
    if (barcode && name && price) {
      setErrors("");
      return true;
    } else {
      setErrors("barcode, name and price.");
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) return;
    handleCommands({ barcode: barcode, name: name, price: price });

    closeModal();
  };

  return (
    <div
      className="modal-container"
      onClick={(e) => {
        if (e.target.className === "modal-container") closeModal();
      }}
    >
      <div className="modal">
        <form>
          <h6 style={{ position: "absolute", right: 3, top: 3 }}>Click outside to close</h6>
          {isUpdate && <div style={{ position: "absolute", left: 170, top: 12 }}>{isUpdate?"Update":"Add new Item"}</div>}
          <div className="form-group">
            <label htmlFor="barcode">Barcode</label>
            <input disabled={isUpdate} name="barcode" onChange={(e) => setBarcode(e.target.value)} value={barcode} />
          </div>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input name="name" onChange={(e) => setName(e.target.value)} value={name} />
          </div>
          <div className="form-group">
            <label htmlFor="Price">Price</label>
            <input name="price" onChange={(e) => setPrice(e.target.value)} value={price} />
          </div>
          {errors && <div className="error">{`Please include: ${errors}`}</div>}
          <button type="submit" className="btn" onClick={handleSubmit}>
            Add
          </button>
        </form>
      </div>
    </div>
  );
};

export default PriceTagModal;
