import React, { useState, useEffect } from "react";
import styles from "../styles/HomeView.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import Nav from "../Components/HomeComponents/Nav";
import "swiper/css/bundle";
import MarketActivationPage from "./MarketActivation";
import InventoryRegPage from "./InventoryRegPage";
import Footer from "../Components/HomeComponents/Footer";

function HomeView() {
  const [num, setNum] = useState(2);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1290) {
        setNum(1);
      } else {
        setNum(2);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [shouldHide, setShouldHide] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const mediaQuery = window.matchMedia("(max-width: 780px)");
      setShouldHide(mediaQuery.matches);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Nav />
        <a className={styles.btnRegist} href="#register-market">
          <p>Sign up</p>
        </a>
      </div>
      <section className={styles.mission}>
        <p className={styles.missionHeader}>
          Eliminating Operatinal Inefficiency in Supermarket Industry.
        </p>
        <p className={styles.missionText}>
          <ul>
            <li>Item Price tag Management.</li>
            <li>Queue-Free Checkout.</li>
            <li>Anti-Shoplifting.</li>
            <li>Best Shopping Experience.</li>
            <li>Global Market Access.</li>
            <li>Markets Discovery.</li>
          </ul>
        </p>
      </section>
      <Swiper
        className={styles.swiper}
        style={{
          display: shouldHide ? "none" : "block",
          width: "100%",
          height: 700,
          padding: 35,
          backgroundColor: "#F1F3F4",
        }}
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={50}
        slidesPerView={num}
        navigation={false}
        pagination={{ clickable: true }}
        autoplay={{ delay: 10000 }}
      >
        <SwiperSlide
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50%",
            padding: 20,
            borderRadius: 8,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            backgroundColor: "#fff",
          }}
        >
          <div style={{ margin: 30 }}>
            <p className={styles.swiperHeader}> Scan and Go</p>
            <p className={styles.swiperText}>
              Elevate your shopping experience with a single scan – effortlessly
              fill your cart by capturing product barcodes
            </p>
          </div>
          <img
            src={require("../photos/scan-qrcode.jpg")}
            alt="Slide 1"
            className={styles.image}
          />
        </SwiperSlide>
        <SwiperSlide
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 20,
            borderRadius: 8,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            backgroundColor: "#fff",
          }}
        >
          <div style={{ margin: 30 }}>
            <p className={styles.swiperHeader}>Checkout</p>
            <p className={styles.swiperText}>
              Experience the thrill of lightning-fast, real-time payments
              facilitating all seamlessly integrated within a single,
              user-friendly App
            </p>
          </div>
          <img
            src={require("../photos/scanning-checkout.jpg")}
            alt="Slide 2"
            className={styles.image}
          />
        </SwiperSlide>
        <SwiperSlide
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 20,
            borderRadius: 8,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            backgroundColor: "#fff",
          }}
        >
          <div style={{ margin: 30 }}>
            <p className={styles.swiperHeader}>Avoid queues</p>
            <p className={styles.swiperText}>
              Download the app today and effortlessly bypass the seemingly
              endless queues at your favorite stores, ensuring a swift and
              enjoyable shopping experience.
            </p>
          </div>
          <img
            src={require("../photos/checkout-queue.webp")}
            alt="Slide 3"
            className={styles.image}
          />
        </SwiperSlide>
        <SwiperSlide
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 20,
            borderRadius: 8,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            backgroundColor: "#fff",
          }}
        >
          <div style={{ margin: 30 }}>
            <p className={styles.swiperHeader}>Dynamic Pricing</p>
            <p className={styles.swiperText}>
              Elevate your retail game with Dynamic price – the hassle-free
              solution for instant price updates, eliminating manual label
              changes. Stay agile, boost efficiency, and simplify your
              operations with ease.
            </p>
          </div>
          <img
            src={require("../photos/price-label.jpg")}
            alt="Slide 4"
            className={styles.image}
          />
        </SwiperSlide>
      </Swiper>
      <p className={styles.sectHeader}>
        Give your customers the best shopping experience.
      </p>
      <div className={styles.services}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            width: "70%",
          }}
        >
          <p className={styles.subHeader}>
            Fund your account, Make Payments with Swinva
          </p>
          <p className={styles.servicesText}>
            Embrace a life of convenience and freedom with our revolutionary
            app. Easily fund your account, make payments directly to the market,
            and liberate yourself from tedious queues. Download the app today
            and take control of your time, making every moment count.
          </p>
          <p className={styles.hiddenText}>
            Thrill in Every Scan: Lightning-Fast Real-Time Transactions, Where
            Convenience Meets Expectation!
          </p>
        </div>
        <img
          src={require("../photos/app-services.jpg")}
          className={styles.servicesImage}
        />
      </div>
      <section id="register-market">
        <MarketActivationPage />
      </section>
      <InventoryRegPage />
      <Footer />
    </div>
  );
}

export default HomeView;
