import React from "react";
import styles from "../styles/About.module.css";
import Nav from "../Components/HomeComponents/Nav";
import Footer from "../Components/HomeComponents/Footer";

const AboutPage = () => {
  return (
    <div className={styles.containerWrapper}>
      <Nav />
      <section className={styles.wrapper}>
        <div className={styles.left}>
          <h3 className={styles.leftHeader}> How It Started</h3>
          <p className={styles.leftBigText}>
            {" "}
            Our Dream is to Revolutionize the Retail System through
            Digitalization
          </p>
          <p className={styles.leftText}>
            Swinva was founded by two passionate tech developers and
            intrapreneurs. Their shared dream was to create a platform that
            promotes digitalization across retail, industries, and education.
            United by their belief in digitalization, they embarked on a journey
            to build Swinva. With relentless dedication, they gathered a team of
            experts and launched this innovative platform, creating a global
            community of users, all connected by the desire to explore.
          </p>
        </div>
        <div className={styles.right}>
          <img
            src={require("../photos/who-we-are.jpg")}
            alt="who we are"
            className={styles.image}
          />
          <div className={styles.boxContainer}>
            <div className={styles.box}>
              <div>
                <p className={styles.innerContent}>3.5</p>
                <p>Years of experience</p>
              </div>
            </div>
            <div className={styles.box}>
              <div>
                <p className={styles.innerContent}>10</p>
                <p>Project Challenge</p>
              </div>
            </div>
            <div className={styles.box}>
              <div>
                <p className={styles.innerContent}>600+</p>
                <p>Positive Reviews</p>
              </div>
            </div>
            <div className={styles.box}>
              <div>
                <p className={styles.innerContent}>Excellent</p>
                <p>Trusted users</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.visionMissioWrapper}>
        <div className={styles.vision}>
          <h3 className={styles.leftHeader}>Our Vision</h3>
          <p className={styles.visionMissionHeaderText}>
            Empowering Lives Through Digitalization
          </p>
          <p className={styles.visionMissionText}>
            To be the undisputed leader in transforming the retail landscape,
            setting the gold standard for efficiency, convenience, and customer
            satisfaction. We envision a future where the traditional shopping
            experience is redefined, characterized by seamless transactions,
            minimal wait times, and a harmonious blend of cutting-edge
            technology and personalized customer benefits.
          </p>
        </div>
        <div className={styles.mission}>
          <h3 className={styles.leftHeader}>Our Mission</h3>
          <p className={styles.visionMissionHeaderText}>
            Making Shopping Faster, Safer and Easier
          </p>
          <p className={styles.visionMissionText}>
            Our mission is to revolutionize the retail industry by leveraging
            cutting-edge technology to reduce wait times, eliminate queues, and
            automate pricing in hypermarkets, supermarkets, superettes, and
            convenience stores. Through lightning-fast real-time payment
            solutions, we aim to enhance customer satisfaction and set a new
            standard for convenience in every transaction.
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AboutPage;
