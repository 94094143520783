import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import Developers from "./Pages/Developers";
import Partners from "./Pages/Partners";
import Contact from "./Pages/Contact";
import About from "./Pages/About";
import Login from "./Pages/Login";
import NotFoundPage from "./Pages/NotFound";
import PasswordReset from "./Pages/PasswordReset";
import HomeView from "./Pages/HomeView";
import { ToastNotification } from "./Components/HomeComponents/ToastNotification";
import MarketMain from "./Components/Market/MarketMain";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeView />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "developers",
    element: <Developers />,
  },
  {
    path: "partners",
    element: <Partners />,
  },
  {
    path: "contact",
    element: <Contact />,
  },
  {
    path: "about",
    element: <About />,
  },
  {
    path: "market",
    element: <MarketMain />,
  },
  {
    path: "reset-password",
    element: <PasswordReset />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);
function App() {
  return (
    <>
      <ToastNotification />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
// createRoutesFromElements(
//   <Route path="HomeView" element={<HomeView />}>
//     <Route path="login" element={<LoginPage />} />
//     <Route path="feature" element={<FeaturesPage />} />
//     <Route path="about" element={<AboutPage />} />
//     <Route path="contact" element={<ContactPage />} />
//     <Route path="app-home" element={<AppHomePage />} />
//     <Route path="market-settings" element={<MarketSettingsPage />} />
//     <Route path="authenticated" element={<AuthenticatedPage />} />
//     <Route path="reset-password" element={<PasswordReset />} />
//     <Route path="*" element={<NotFoundPage />} />
//   </Route>
// )
