import React, { createContext, useState } from "react";
export const AuthContext = createContext()

const AuthContextProvider = (props) => {

    const [token, setToken] = useState('');
    const [tokenTTL, setTokenTTL] = useState(0)
    const [tokenLifeSpan, setTokenLifeSpan]=useState(0)
    const [role, setRole] = useState('')

    const loginUser = (user) => {
        let ttl=new Date().getTime();
         ttl+=(user.tokenLifeSpanInMinutes-1)* 60 * 1000;
        setTokenTTL(ttl)
        setTokenLifeSpan(user.tokenLifeSpanInMinutes)
        setRole(user.role)
        setToken(user.accessToken)
    };
    const updateToken = token => {
        setToken(token)
        let ttl=new Date().getTime();
        ttl=+(tokenLifeSpan-1)* 60 * 1000;
       setTokenTTL(ttl)
    }
    const logoutUser = () => {
        setToken('')
        setTokenTTL(0)
        setRole('')
    };
    return (
        <AuthContext.Provider value={{ token, tokenTTL, role,  updateToken, loginUser, logoutUser}}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;


