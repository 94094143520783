

const ImportPriceTage = ({importedItems}) => {


    return ( <div>
        {importedItems.length > 0 && (
         <div>
            
            <table className="table">
            <thead>
                <tr>
                {Object.keys(importedItems[0]).map((key) => (
                    <th key={key}>{key}</th>
                ))}
                </tr>
            </thead>
            <tbody>
                {importedItems.map((row, index) => (
                <tr key={index}>
                    {Object.values(row).map((value, index) => (
                    <td key={index}>{value}</td>
                    ))}
                </tr>
                ))}
            </tbody>
            </table>
        </div>
      )}
    </div> );
}
 
export default ImportPriceTage;