import styles from "./DevelopersStyles.module.css";

const UpsertItemsAPI = () => {
  return (
    <div className={styles.section}>
      <h2 className={styles.subSubSubHeading}>API Integration</h2>

      <p className={styles.paragraph}>
          These REST APIs, UpsertItems, and DeleteItems, cater to any inventory or sales management software that aids different supermarket stores in managing their item price tags in real-time once integrated.
        </p>

      {/* UpsertItems API */}
      <div className={styles.subSection}>
        <h3 className={styles.titleHeading}>UpsertItems API</h3>
        <p className={styles.paragraph}>
        This API facilitates the addition of new item price tags and updates existing ones. The request body accepts a collection of item price tags along with the customer's secret key. For existing price tags, both the price and description will be updated, while new ones will be created.
        </p>

        {/* UpsertItems Endpoint */}
        <div className={styles.subSubSection}>
          <h4 className={styles.subSubSubHeading}>UpsertItems API Endpoint</h4>
          <ul className={styles.list}>
            <li>HTTP Method: POST</li>
            <li>Path: /price-tag</li>
          </ul>
        </div>

        {/* UpsertItems Headers */}
        <div className={styles.subSubSection}>
          <h4 className={styles.subSubSubHeading}>UpsertItems Headers</h4>
          <ul className={styles.list}>
            <li>Media Type: application/json (400 Bad Request: format not supported)</li>
          </ul>
        </div>

        {/* UpsertItems Request Body */}
        <div className={styles.subSubSection}>
          <h4 className={styles.subSubSubHeading}>UpsertItems Request Body</h4>
          <p className={styles.paragraph}>
            Object containing Market secret key and a collection of item objects.
          </p>
          <pre className={styles.code}>
            {`{
  "secretKey": "string",
  "items": [
    {
      "itemCode": "string",
      "description": "string",
      "price": "decimal or number"
    }
  ]
}`}
          </pre>
          <h4 className={styles.subSubSubHeading}>UpsertItems Request Body Example</h4>
          <pre className={styles.code}>
            {`{
    "secretKey": "your_secret_key",
    "items": [
        {
            "itemCode": "123455676",
            "description": "Product A",
            "price": 19.99
        },
        {
            "itemCode": "4567789900",
            "description": "Product B",
            "price": 29.99
        }
    ]
}  
`}
          </pre>
        </div>

        {/* UpsertItems Request Constraints */}
        <div className={styles.subSubSection}>
          <h4 className={styles.subSubSubHeading}>UpsertItems Request Constraints</h4>
          <ul className={styles.list}>
            <li>Maximum of 5,000 items per upsert request.</li>
            <li>Maximum of 120,000 unique items per market.</li>
          </ul>
        </div>

        {/* UpsertItems Response */}
        <div className={styles.subSubSection}>
          <h4 className={styles.subSubSubHeading}>UpsertItems Response</h4>
          <ul className={styles.list}>
            <li>Successful upsert: 200 OK with a response body indicating the number of inserted and updated items.</li>
            <pre className={styles.code}>
              {`{
  "inserted": count,
  "updated": count
}`}
            </pre>
            <li>Invalid secret key: 401 Unauthorized.</li>
            <li>Invalid data: 400 Bad Request.</li>
          </ul>
        </div>

        {/* UpsertItems Example */}
        <div className={styles.subSubSection}>
          <h4 className={styles.subSubSubHeading}>UpsertItems Example</h4>
          <pre className={styles.code}>
            {`curl -X POST \\
  -H "Content-Type: application/json" \\
  -d '{
  "secretKey": "your_secret_key",
  "items": [
    {
      "itemCode": "123455676",
      "description": "Product A",
      "price": 19.99
    },
    {
      "itemCode": "4567789900",
      "description": "Product B",
      "price": 29.99
    }
  ]
}' \\
  https://api.swinva.com/price-tag`}
          </pre>
          <h4 className={styles.subSubSubHeading}>UpsertItems Response Body Example (200 OK):</h4>
          <pre className={styles.code}>
            {`{
  "inserted": 1,
  "updated": 1
}`}
          </pre>
          <h4 className={styles.subSubSubHeading}>What to consider</h4>
          <p>
When integrating APIs, it's advisable to handle failed calls by storing items as pending, 
possibly due to issues such as client network disruptions or other factors. Additionally, regularly check this storage for pending items and attempt to resend them. If the resend operation 
is successful or have an indicator and a retry option, clear the storage to ensure seamless and reliable data synchronization. </p>
        </div>
      </div>
    </div>
  );
};

export default UpsertItemsAPI;
