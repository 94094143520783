import { useState } from "react";
import styles from "../styles/InventoryRegPage.module.css";
import ApiService from "../ApiService";

const AppRegistrationPage = () => {
  // const [appEmail, setAppEmail] = useState("");
  // const [appName, setAppName] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [linkToAppPortal, setLinkToAppPortal] = useState("");

  // const [isPosting, setPostErr] = useState("");
  // const [postDataError, setIsPosting] = useState(false);

  // const handleSubmit = async () => {
  //   const AppMarketByEmail = {
  //     appEmail,
  //     appName,
  //     phoneNumber,
  //     linkToAppPortal,
  //   };
  //   setIsPosting(true);
  //   setPostErr("");
  //   ApiService.postAsync("EnterpriseApps", AppMarketByEmail)
  //     .then((resp) => {
  //       alert("success");
  //     })
  //     .catch((err) => {
  //       setPostErr(err.message);
  //     })
  //     .finally(setIsPosting(false));
  // };
  return (
    <div className={styles.container}>
      <p className={styles.bigText}>
        Activate Swinva for your supermarket inventory & POS system for free
      </p>
      <div className={styles.inventoryBackground}>
        <div className={styles.formContainer}>
          <img
            src={require("../photos/AI.jpeg")}
            alt=""
            className={styles.aiImage}
          />
        </div>
      </div>
    </div>
  );
};
export default AppRegistrationPage;
