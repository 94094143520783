import styles from "./DevelopersStyles.module.css";

const DeleteItemsAPI = () => {
  return (
    <div className={styles.section}>
      <h2 className={styles.titleHeading}>Integrate DeleteItems API</h2>
      <div className={styles.subSection}>
        <h4 className={styles.subSubSubHeading}>DeleteItems API Endpoint</h4>
        <ul className={styles.list}>
          <li>HTTP Method: PUT</li>
          <li>Path: /price-tag/delete</li>
        </ul>
        {/* UpsertItems Headers */}
        <div className={styles.subSubSection}>
          <h4 className={styles.subSubSubHeading}>DeleteItems Headers</h4>
          <ul className={styles.list}>
            <li>Media Type: application/json (400 Bad Request: format not supported)</li>
          </ul>
        </div>

        {/* UpsertItems Request Body */}
        <div className={styles.subSubSection}>
          <h4 className={styles.subSubSubHeading}>DeleteItems Request Body</h4>
          <p className={styles.paragraph}>
            Object containing the Market secret key and an array of item codes to be deleted.
          </p>
          <pre className={styles.code}>
            {`{
      secretKey: string,
      itemCodes: [string, string, …]
}
`}
          </pre>
          <h4 className={styles.subSubSubHeading}>DeleteItems Request Body Example</h4>
          <pre className={styles.code}>
            {`{
  "secretKey": "your_secret_key",
  "itemCodes": ["123455676", "4567789900"]
}  
`}
          </pre>
        </div>

        {/* UpsertItems Request Constraints */}
        <div className={styles.subSubSection}>
          <h4 className={styles.subSubSubHeading}>DeleteItems Request Constraints</h4>
          <ul className={styles.list}>
            <li>Maximum of 5,000 items per upsert request.</li>
          </ul>
        </div>

        {/* UpsertItems Response */}
        <div className={styles.subSubSection}>
          <h4 className={styles.subSubSubHeading}>DeleteItems Response</h4>
          <ul className={styles.list}>
            <li>Successful Delete: 201 NoContent.</li>
            <li>Invalid secret key: 401 Unauthorized.</li>
            <li>Invalid data: 400 Bad Request.</li>
          </ul>
        </div>

        {/* DeleteItems Example */}
        <div className={styles.subSubSection}>
          <h4 className={styles.subSubSubHeading}>DeleteItems Example</h4>
          <pre className={styles.code}>
            {`curl -X PUST \\
-H "Content-Type: application/json" \\
-d '{
  "secretKey": "your_secret_key",
  "itemCodes": ["123455676", "4567789900"]
}
' \\
https://api.swinva.com/price-tag/delete`}
          </pre>
        </div>
      </div>
    </div>
  );
};

export default DeleteItemsAPI;
