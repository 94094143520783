import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AuthContextProvider from './Contexts/AuthContext';
import { UserContext } from './Contexts/UserContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <UserContext>
        {userContextValue => (
          <App userContextValue={userContextValue} />
        )}
      </UserContext>
    </AuthContextProvider>
  </React.StrictMode>,
);
