import { useState } from "react";
import SettingsRendering from "./SettingsRendering";
import SettingsNavbar from "./settingsNavbar";
import styles from "./Settings.module.css"

const MarketSettingsPage = () => {
    const [activeTab, setActiveTab] = useState('key-gen');
    return ( 
        <div className={styles.settingContainer}>
           <SettingsNavbar activeTab={activeTab} setActiveTab={setActiveTab}/>
           <SettingsRendering activeTab={activeTab}/>
        </div>
     );
}
 
export default MarketSettingsPage;