import styles from "./DevelopersStyles.module.css"
const ReadShoppedItems = () => {
    return ( 
        <div className={styles.section}>
          <h2 className={styles.titleHeading}>Read Shopped Items on a single scan</h2>
          <p className={styles.paragraph}>
            Before now, when a customer shopped items, each of the items had to be scanned on the computer for the sale management 
            software to read the item code, which was encoded either as a barcode or QR code. The item code was then used to get the item details and 
            set the quantity. This process is repeated for all the items a customer shopped.
          </p>
          <p className={styles.paragraph}>
            When supporting mobile shopping, after retrieving the scanned string, you must check if it is coming from mobile by checking if the string 
            contains "&". The string coming from mobile contains all the item codes with their respective quantities in the following format:
            <br /> <br/>
                <h3>  "item1code item1quantity, item2code item2quantity, item2code item2quantity, …&id"</h3>
                <br />
                <h3>example:  "12345556 3, 674683633 5, 546355345 1, …&id"</h3>
                <br />
                <p>Retrieve all the item codes, use them to fetch additional information about the purchased items, and then populate the cat screen with the gathered data.</p>
          </p>
          
          <h3 className={styles.subSubHeading}>1. Extracting Item Information:</h3>
          <ul className={styles.list}>
            <li>The scanned string is split at the '&' character to separate the item information from the additional identifier (e.g., "id").</li>
            <li>The item information is further split by ',' to obtain pairs of item code and quantity.</li>
          </ul>
          <h3 className={styles.subSubHeading}>2. Building Purchase Items Array:</h3>
          <ul className={styles.list}>
            <li>For each pair, the item code and quantity are extracted by splitting at the space (' ') character.</li>
            <li>The resulting pairs are pushed into an array named purchItems in the format {`{itemCode, quantity}` }.</li>
          </ul>
          <h3 className={styles.subSubHeading}>3. Fetching Additional Item Information:</h3>
          <ul className={styles.list}>
            <li>The item codes of the purchased items are extracted and used to send a fetch request to obtain additional details and populate the cart.</li>
          </ul>
          <h4 className={styles.subSubSubHeading}>What to consider</h4>
          <li> When retrieving customer-shopped items to populate the cart screen, if an item is scanned again and is already in the cart, consider emphasizing the corresponding row. This enhancement facilitates the easy identification of items on the screen for a more user-friendly experience.</li>
          <h4 className={styles.subSubSubHeading}>Js script code example:</h4>
          <pre className={styles.code}>
            {`const [purchasedItems, setPurchaseItems] = useState([]);

const onScannedSwinva = scannedString => {
    const itemsString = scannedString.split('&')[0].trim();
    const pairs = itemsString.split(',');
    const purchItems = [];

    pairs.forEach(pair => {
        const [itemCode, quantity] = pair.trim().split(' ');

        purchItems.push({
            itemCode,
            quantity: parseInt(quantity),
        });
    });

    var barcodes = purchItems.map(x => x.itemCode);
    sendFetchProductWithBarcode.send(JSON.stringify(barcodes))
        .then(resp => {
            var processedPurchaseItems = GetPurchase(resp, purchItems);
            setPurchaseItems(processedPurchaseItems);
        });
}`}
          </pre>
          <h4><i>If encountered any difficulties, please don't hesitate to contact us through our dedicated contact page. We're here to assist you and address any challenges you may be facing.</i></h4>
        </div>
     );
}
 
export default ReadShoppedItems;