import {BsFillPencilFill,BsFillTrashFill} from "react-icons/bs"
import "./Pricetagetable.css"
import { useState } from "react";

const PriceTageTable = ({records, editItem, handleCommands}) => {
    const [search, setSearch]=useState("")
    return ( 
    <div className="table-wrapper">
        <div >
            <form  style={{width:450, paddingBottom:0, borderRadius:5}}>
                <input style={{fontSize:20, padding:2}} type="text" placeholder="search with barcode"  onChange={(e)=>setSearch(e.target.value)}/>
            </form>
        </div>
        <table className="table">
            <thead>
                <tr>
                    <th>Sn</th>
                    <th>Barcode</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody style={{fontSize:11}}>
                {records.filter((item)=>{
                    return search.trim()===''?item
                    : item.barcode.includes(search);
                }).map((item, indx)=>{
                    return(
                        <tr key={item.barcode}>
                            <td>{indx+1}</td>
                            <td>{item.barcode}</td>
                            <td style={{maxWidth: 300}}>{item.name}</td>
                            <td>{item.price}</td>
                            <td className="fit">
                            <span className="actions">
                                <BsFillTrashFill
                                className="delete-btn"
                                onClick={() => handleCommands(item, "delete")}
                                />
                                <BsFillPencilFill 
                                className="edit-btn"
                                onClick={() => editItem(item)}
                                />
                            </span>
                        </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    </div> );
}
 
export default PriceTageTable;