import React, { useState } from "react";
import Nav from "../Components/HomeComponents/Nav";
import styles from "../styles/Contact.module.css";
import { MdLocationOn } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import ApiService from "../ApiService";
import { showToast } from "../Components/HomeComponents/ToastNotification";

const Contact = () => {
  const [name, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [isPosting, setIsPosting] = useState(false);
  const [postErr, setPostErr] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsPosting(true);
    setPostErr("");
    ApiService.postAsync("Contacts", {
      name: name,
      email: email,
      message: message,
    })
      .then((resp) => {
        showToast("Submitted", "success");

        setFullName("");
        setEmail("");
        setMessage("");
      })
      .catch((err) => {
        showToast(err, "error");
        setPostErr(err.message);
      })
      .finally(() => {
        setIsPosting(false);
      });
  };

  return (
    <div>
      <Nav />
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.header}>Contact Us</h1>
          <p className={styles.contactText}>
            Our dedicated team is available 24/7, every day of the week. Your
            inquiries are our top priority, and we're committed to responding as
            promptly as possible.
          </p>
          <div className={styles.infoWrapper}>
            <div className={styles.contactInfo}>
              <div className={styles.address}>
                <div
                  style={{
                    height: 30,
                    width: 30,
                    borderRadius: 15,
                    backgroundColor: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 20,
                  }}
                >
                  <MdLocationOn />
                </div>
                <div style={{ margin: 10 }}>
                  <h4
                    style={{ color: "#208393", marginTop: 3, marginLeft: 10 }}
                  >
                    Address
                  </h4>
                  <p
                    style={{
                      color: "#f6f1ee",
                      marginLeft: 10,
                      width: "60%",
                      lineHeight: 1.5,
                      marginTop: 5,
                    }}
                  >
                    Plot 4, Woji Estate Port-Harcourt Rivers State, Nigeria
                  </p>
                </div>
              </div>
              <div className={styles.address}>
                <div
                  style={{
                    height: 30,
                    width: 30,
                    borderRadius: 15,
                    backgroundColor: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 20,
                  }}
                >
                  <FaPhoneAlt />
                </div>
                <div style={{ margin: 10 }}>
                  <h4
                    style={{ color: "#208393", marginTop: 3, marginLeft: 10 }}
                  >
                    Phone
                  </h4>
                  <p style={{ color: "#f6f1ee", marginLeft: 10, marginTop: 5 }}>
                    +234 703 963 1235
                  </p>
                </div>
              </div>
              <div className={styles.address}>
                <div
                  style={{
                    height: 30,
                    width: 30,
                    borderRadius: 15,
                    backgroundColor: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 20,
                  }}
                >
                  <MdOutlineMail />
                </div>
                <div style={{ margin: 10 }}>
                  <h4
                    style={{ color: "#208393", marginTop: 3, marginLeft: 10 }}
                  >
                    Email
                  </h4>
                  <p style={{ color: "#f6f1ee", marginLeft: 10, marginTop: 5 }}>
                    info@swinva.com
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.formContainer}>
              <h3 style={{ marginLeft: 16 }}>Send Message</h3>
              <form onSubmit={handleSubmit} className={styles.messageForm}>
                <div>
                  <label
                    style={{ display: "block", marginBottom: 5 }}
                    htmlFor="fullName"
                  >
                    Full Name:
                  </label>
                  <input
                    type="text"
                    id="fullName"
                    value={name}
                    onChange={(e) => setFullName(e.target.value)}
                    required
                    style={{
                      width: "100%",
                      padding: 8,
                      boxSizing: "borderBox",
                      border: 1,
                      borderRadius: 4,
                      fontSize: 16,
                      backgroundColor: "#eee",
                    }}
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    style={{ display: "block", marginBottom: 5 }}
                  >
                    Email:
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    style={{
                      width: "100%",
                      padding: 8,
                      boxSizing: "borderBox",
                      border: 1,
                      borderRadius: 4,
                      fontSize: 16,
                      backgroundColor: "#eee",
                    }}
                  />
                </div>
                <div>
                  <label
                    htmlFor="message"
                    style={{ display: "block", marginBottom: 5 }}
                  >
                    Type your message:
                  </label>
                  <textarea
                    id="message"
                    placeholder="Type your message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    style={{
                      width: "100%",
                      padding: 8,
                      boxSizing: "borderBox",
                      border: 1,
                      borderRadius: 4,
                      fontSize: 16,
                      backgroundColor: "#eee",
                    }}
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    style={{ width: 60, backgroundColor: "#00BCD7" }}
                  >
                    {isPosting ? "Sending..." : "Send"}
                  </button>
                </div>
                {postErr && (
                  <small
                    style={{
                      color: "#CE5A67",
                      textAlign: "center",
                      margin: 15,
                    }}
                  >
                    Error: {postErr}
                  </small>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
