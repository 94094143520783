import React, { useState } from "react";
import ApiService from "../../ApiService";
import Styles from "../../Components/SettingsComponents/Settings.module.css";

const AccountDeletion = () => {
  const [reason, setReason] = useState("");
  const [Password, setPassword] = useState("");
  const [isPosting, setIsPosting] = useState(false);
  const [postErr, setPostErr] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPosting(true);
    setPostErr("");
    ApiService.putAsync("Users/delete", {
      reason: reason,
      password: Password,
    })
      .then((resp) => {
        alert("success");
      })
      .catch((err) => {
        setPostErr(err.message);
      })
      .finally(() => {
        setIsPosting(false);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className={Styles.form}>
        <p className={Styles.formTitle}>Delete Account permanently</p>
        <div>
          <label style={{ color: "#352F44" }}>Reason:</label>
          <input
            type="text"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            required
            className={Styles.formInput}
          />
        </div>
        <div>
          <label style={{ color: "#352F44" }}>Password:</label>
          <input
            type="password"
            value={Password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className={Styles.formInput}
          />
        </div>
        <button type="submit" className={Styles.btn}>
          {isPosting ? "deleting..." : "delete account"}
        </button>
        {postErr && (
          <small
            style={{
              color: "#CE5A67",
              textAlign: "center",
              margin: 15,
            }}
          >
            Error: {postErr}
          </small>
        )}
      </form>
    </div>
  );
};

export default AccountDeletion;
