import styles from "../../styles/Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.footerLeft}>
          <h3>Swinva</h3>
          <p className={styles.swinvaText}>
            We are technology provider with the vision of revolutionising retail
            by reducing queues, ensuring top-notch security, automating price
            labeling, and offering lightning-fast payment processing, all in one
            seamless solution.
          </p>
        </div>
        <div className={styles.footerCenter}>
          <h3>Quick Links</h3>
          <ul className={styles.footerLinks}>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/partners">Partners</a>
            </li>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </div>
        <div className={styles.footeRight}>
          <h3>Contact Us</h3>
          <p style={{ marginTop: 10 }}>
            Plot 4, Woji Estate Port-Harcourt Rivers State,
            <br />
            Nigeria
            <br />
            <br />
            <i className="fa fa-phone"></i> +234 7039631235
            <br />
            <i className="fa fa-envelope"></i> info@swinva.com
          </p>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <p>&copy; {new Date().getFullYear()} Swinva | All rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
