import { useState } from "react";
import countryData from "../../Data/countryData";
import ApiService from "../../ApiService";
import UploadLogo from "./UploadLogo";
import Styles from "../../styles/UpdateMarket.module.css";

const UpdateMarket = ({ data }) => {
  const [isUpdating, setIsUpdateing] = useState(false);
  const [updateError, setUpdateError] = useState("");
  const [latitude, setLatitude] = useState(data.latitude);
  const [longitude, setLongitude] = useState(data.longitude);
  const [address, setAddress] = useState(data.streetAddress);
  const [country, setCountry] = useState(data.country);
  const [name, setName] = useState(data.name);
  const [currency, setCurrency] = useState(data.currency);
  const [mobileShopping, setMobileShopping] = useState(data.mobileShopping);
  const [payWithApp, setPayWithApp] = useState(data.payWithApp);

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    const selectedCountryObj = countryData.find(
      (countryObj) => countryObj.country === event.target.value
    );
    setCurrency(selectedCountryObj.currencyCode);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const updateData = {
      name: name,
      currency: currency,
      mobileShopping: mobileShopping,
      payWithApp: payWithApp,
      latitude: latitude,
      longitude: longitude,
      streetAddress: address,
      country: country,
    };
    setIsUpdateing(true);
    setUpdateError("");
    ApiService.putAsync("Markets", updateData)
      .then((resp) => {})
      .catch((err) => {
        setUpdateError(err.message);
      })
      .finally(() => {
        setIsUpdateing(false);
      });
  };
  const setLocation = (e) => {
    e.preventDefault();
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setLatitude(pos.coords.latitude);
        setLongitude(pos.coords.longitude);
      },
      (err) => console.error(err)
    );
  };

  const handleUploadCountries = async () => {
    var countries = [];
    for (let index = 0; index < countryData.length; index++) {
      const element = countryData[index];
      countries.push({ name: element.country, currency: element.currencyCode });
    }
    ApiService.postAsync("countries", { countries })
      .then((res) => {
        alert("uploaded");
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <div className={Styles.container}>
      <UploadLogo />
      <section className={Styles.formSect}>
        <form className={Styles.form}>
          <div>
            <label style={{ color: "#352F44" }}>Geocoder</label>

            <div className={Styles.getLocation}>
              <small>
                <p className={Styles.locationText}>
                  To acurately get your geocoder, be at the center of your
                  market and.
                </p>
                <button onClick={setLocation} className={Styles.btnLocation}>
                  click here
                </button>
              </small>
            </div>
            <div className={Styles.location}>
              <div style={{ display: "flex" }}>
                <label className={Styles.label}>Latitude: </label>
                <label style={{ marginLeft: 5 }}>{latitude}</label>
              </div>
              <div style={{ display: "flex" }}>
                <label className={Styles.label}>Longitude: </label>
                <label style={{ marginLeft: 5 }}>{longitude}</label>
              </div>
            </div>
          </div>
          <div className={Styles.formFieldSect}>
            <div style={{ marginTop: 20 }}>
              <label className={Styles.label}>Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className={Styles.formInput}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <label className={Styles.label}>Full Local Address</label>
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                required
                className={Styles.formInput}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <label htmlFor="country" className={Styles.label}>
                Select your country with currency:
              </label>
              <select
                id="country"
                value={country}
                onChange={handleCountryChange}
                className={Styles.formSelect}
              >
                <option value="">Select</option>
                {countryData.map((countryObj) => (
                  <option key={countryObj.country} value={countryObj.country}>
                    {countryObj.country}: ({countryObj.currencyCode})
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className={Styles.checkboxes}>
            <label className={Styles.label}>
              <input
                className={Styles.checkbox}
                type="checkbox"
                checked={mobileShopping}
                onChange={(e) => setMobileShopping(e.target.checked)}
              />
              Mobile Shopping
            </label>
            <label className={Styles.label}>
              <input
                type="checkbox"
                checked={payWithApp}
                onChange={(e) => setPayWithApp(e.target.checked)}
                disabled={!data.isPaymentActivated}
              />
              Pay with App
            </label>
          </div>
          <button onClick={handleUpdate} className={Styles.btn}>
            {" "}
            {isUpdating ? "Submitting..." : "Submit"}
          </button>
          {updateError && (
            <small
              style={{
                color: "#CE5A67",
                textAlign: "center",
                margin: 16,
              }}
            >
              Error: {updateError}
            </small>
          )}
        </form>
      </section>
      {/* <button
      style={{width:200}}
       onClick={handleUploadCountries}>Upload countries</button> */}
    </div>
  );
};

export default UpdateMarket;
