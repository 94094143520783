import React, { useEffect, useState, useRef } from "react";
import styles from "../../styles/MarketSale.module.css";
import Payment from "./Payment";
import { useNativeApi } from "../../WebBridge/useNativeApi";
import ApiService from "../../ApiService";
import Receipt from "./Receipt";
import { useReactToPrint } from 'react-to-print';
import useScanDetection from "use-scan-detection-react18";
import SalesNotification from "./SalesNotification";

const MarketSale = ({market, items}) => {
  

  const [date, setDate] = useState(new Date());
  const [formattedDate, setFormattedDate]=useState(`${date.getDate()} - ${date.toLocaleString("default", {
    month: "long",
  })} - ${date.getFullYear()}`)
  
 const [confirmPayments, setConfirmPayment]=useState([])
  const [showBankInfo, setShowBankInfo] = useState(false);
  const [showConfirmedPayment, setShowConfirmPayment] = useState(false);
  const [purchasedItems, setPurchasedItems]=useState([])
  const [paymentData, setPaymentData]=useState(null)

  const toggleBankInfo = () => {
    setShowBankInfo(!showBankInfo);
  };

  const toggleConfirmPayment=()=>{
    setShowConfirmPayment(!showConfirmedPayment)
  }

  const[DailySales, setData]=useState([])

  const [loadingPurchase, setLoadingPurchase]=useState(false)
  const [loadPurchaseErr, setLoadPurchaseErr]=useState("")

  useEffect(() => {
    if(date<=Date.now() && market.bankId && market.payWithApp){
      const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000)
      setLoadingPurchase(true)
      setLoadPurchaseErr("")
      ApiService.getAsync(`Markets/purchases/${utcDate.toISOString()}`)
      .then(resp=>{
        setData(resp)
      }).catch(err=>{
        setLoadPurchaseErr(err.message)
      }).finally(()=>{
        setLoadingPurchase(false)
      })
    }
    if(date<=Date.now()){
      setFormattedDate(`${date.getDate()} - ${date.toLocaleString("default", {
        month: "long",
      })} - ${date.getFullYear()}`)
      
      
      handleFetchPayment()
    }
    setLoadingPurchase(false)
  }, [date]);

  const HandleAddNewPurchase=(purch)=>{
    setData(...DailySales, purch)
  }

  const sendPayment = useNativeApi('sendPaymentRequest', 'sendPaymentResponse');
  const fetchPayment = useNativeApi('queryPaymentRequest', 'queryPaymentResponse');
  
  const handleFetchPayment=()=>{
    const fetchPayload=JSON.stringify({date})
    fetchPayment.send(fetchPayload).then((response)=>{
      setConfirmPayment(response.payload)
    })
  }

  useScanDetection({
    onComplete:(code)=>{
        onScannedCode(code)
    },
    minLength:4
  })

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
    const onScannedCode=sannedString=>{
      const splitArray = sannedString.split('&');
      const id = splitArray[splitArray.length - 1].trim();
      if(!id || id.length!==36) return;
      if(id.split("-").length!==5) return;
      const purchase= DailySales.find(p=>p.id===id)
      if(purchase){
        const createddate=formatUtcDate(purchase.createdDate)
          const paymentObject = { id: purchase.id, amount: purchase.amount, createdDate: createddate , customer: purchase.customer};
       
         const payload= JSON.stringify(paymentObject)
  
         sendPayment.send(payload).then((response) => {
          if(!response.isSuccess && !response.alreadyConfirmed)
          {
            alert(response.reasonPhrase)
            return;
          }

          if(!response.isSuccess && response.alreadyConfirmed)
          {
            const msg=`${response.reasonPhrase} \n\n Do you want to regenerate a receipt?`
            const shouldContinue = window.confirm(msg);
            if(!shouldContinue) {
              return;
            }
          }

          if(response.isSuccess){
            setPaymentData({
              id:paymentObject.id,
              customer:paymentObject.customer,
              createdDate:paymentObject.createdDate,
              market:market.name,
              address:market.streetAddress,
              currency:market.currency,
              amount:paymentObject.amount
            })
            const itemsString= sannedString.split('_')[0].trim();
            const pairs = itemsString.split(',');
            const purchItems = [];
            pairs.forEach(pair => {
              const [barcode, quantity] = pair.trim().split(' ');
             
              const existingItem = items.find(item => item.barcode === barcode);
              const amount = existingItem ? existingItem.amount * parseInt(quantity) : 0;

              purchItems.push({
                barcode,
                quantity: parseInt(quantity),
                amount
              });
            });
  
            setPurchasedItems(purchItems)
            handlePrint()
             setPurchasedItems([])
            setPaymentData(null)
          }else if(response.multipleTrierError)return
          else
            alert(response.reasonPhrase) 
        });
      }else{
        alert("invalid payment.")
        //
      }
  }
  const maxDate = new Date().toISOString().split('T')[0];

  const formatDate = dateString => {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleTimeString();
  };
  const formatUtcDate = utcDateString => {
    const utcDate = new Date(utcDateString);
    const dateObj=new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000)
    return dateObj.toLocaleTimeString(); 
  };
  
  const [search, setSearch]=useState("")

  const buttonStyle = {
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    padding: '4px 8px',
    cursor: 'pointer',
    marginBottom: '16px',
    fontSize:'small',
    width:'100%'
  };

  const sectionStyle = {
    display: showBankInfo ? 'block' : 'none',
    marginTop: '16px',
    border: '1px solid #ccc',
    padding: '12px',
  };

  const infoStyle = {
    marginBottom: '8px',
  };

  return (
    <div className={styles.tableContainer}>
      {/* <button onClick={handlePrint}>print</button> */}
      {/* <div  ref={componentRef}>
          <Receipt/>
      </div> */}
      
      {/* {purchasedItems.length>0 &&  <Receipt receiptData={paymentData} items={purchasedItems} ref={componentRef}/>} */}
      {!market.isPaymentActivated && !market.bank && <Payment country={market.country}/>}
      {!market.isPaymentActivated && market.bank && <h5>Kindly proceed to any {market.bank} to complete the payment support.</h5>}
      {market.isPaymentActivated && 
      <div>
      <button onClick={toggleBankInfo} style={buttonStyle}>
        {showBankInfo ? 'Hide Payment Information' : 'Show Payment Information'}
      </button>
      <div style={sectionStyle}>
        <h2>Account Information</h2>
        <div style={infoStyle}>
          <h6 style={infoStyle}>Bank: {market.bank}</h6>
          <h6 style={infoStyle}>Name: {market.paymentName}</h6>
          <h6 style={infoStyle}>Email: {market.email}</h6>
          <h6 style={infoStyle}>Phone No: {market.phone}</h6>
        </div>
        <h2>Bank Contact Information</h2>
        <div style={infoStyle}>
          <h6 style={infoStyle}>Phone No: {market.bankPhone1}</h6>
          <h6 style={infoStyle}>Phone No 2: {market.bankPhone2}</h6>
          <h6 style={infoStyle}>Email: {market.bankEmail}</h6>
        </div>
      </div>
    </div>}

      
      
        <div className="payment-tables">
        {market.bank && <button onClick={toggleConfirmPayment} style={buttonStyle}>
        
        {showConfirmedPayment ? 'show Sales' : 'show confirmed payment'}
      </button>}
      <div className={styles.heading}>
        <div>
          {" "}
          <p style={{ fontSize: 12, fontWeight: 700 }}>
            {showConfirmedPayment && <span>Confirmed on</span>} {!showConfirmedPayment && <span>Sales On</span>} <input type="date" max={maxDate} min={'2023-12-01'} onChange={(e) => setDate(new Date(e.target.value))} />
          </p>
        </div>
        <div>
          {" "}
          <p style={{ fontSize: 12, fontWeight: 700 }}>
            {formattedDate}
          </p>
        </div>
        <div>
          <p style={{ fontSize: 12, fontWeight: 700 }}>{DailySales && "Total Sale: "+ DailySales.length}</p>
          {" "}
        </div>
        <div>
          {" "}
          <p style={{ fontSize: 12, fontWeight: 700 }}>
              {DailySales && "Total Amount: "+market.currency +" "+ DailySales.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0)}
          </p>
        </div>
      </div>
      <div >
        {market.payWithApp && <SalesNotification marketId={market.id} AddNewPurchase={HandleAddNewPurchase}/>}
            <form style={{width:500, paddingBottom:0, paddingTop:5}} >
                <input style={{padding:2, fontSize:16}} type="text" placeholder="search with Id or customer"  onChange={(e)=>setSearch(e.target.value)}/>
            </form>
        </div>
        {showConfirmedPayment && 
         <div className="confirm-payment">
         <table>
          <thead className={styles.table}>
              <tr>
                <th>S/N</th>
                <th>Payment Id</th>
                <th>Amount</th>
                <th>Customer</th>
                <th>payment made at</th>
                <th>Checkout at</th>
                <th>checkout system</th>
              </tr>
            </thead>
            <tbody>
            {confirmPayments.filter((item)=>{
              return search.trim()===''?item
              : item.customer.includes(search) ||item.id.includes(search);
            }).map((item, indx) => (
              <tr key={indx}>
                <td>{indx+1}</td>
                <td>{item.id}</td>
                <td>{market.currency}{item.amount}</td>
                <td>{item.customer}</td>
                <td>{formatDate(item.createdDate)}</td>
                <td>{formatDate(item.checkoutTime)}</td>
                <td>{item.machine}</td>
              </tr>
            ))}
          </tbody>
          </table>
         </div>
        }
          {!showConfirmedPayment &&
             <div className="sales">
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Payment Id</th>
                    <th>Amount</th>
                    <th>Customer</th>
                    <th>Time</th>
                  </tr>
                </thead>
                {DailySales && 
                <tbody>
                {DailySales.filter((item)=>{
                  return search.trim()===''?item
                  : item.customer.includes(search) ||item.id.includes(search);
                }).map((item, indx) => (
                  <tr key={indx}>
                    <td>{indx+1}</td>
                    <td>{item.id}</td>
                    <td>{market.currency}{item.amount}</td>
                    <td>{item.customer}</td>
                    <td>{formatUtcDate(item.createdDate)}</td>
                  </tr>
                ))}
              </tbody>
                }
                {loadingPurchase && <h1>Loading ...</h1>}
                {!loadingPurchase && loadPurchaseErr && <h1>{loadPurchaseErr}</h1>}
              </table>
             </div>
          }
        </div>
      
    </div>
  );
};

export default MarketSale;
